import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Tab, Tabs } from "@mui/material";

import { Holidays } from "./Holidays";
import { Vacations } from "./Vacations";

import styles from "./styles.module.scss";

type Tab = "weekends_and_holidays" | "vacations" | "sick_leave";

export const RecordingWeekends = () => {
  const [activeTab, setActiveTab] = useState<Tab>("weekends_and_holidays");
  const { t } = useTranslation();

  const handleActiveTab = (_: React.SyntheticEvent, newValue: Tab) => {
    setActiveTab(newValue);
  };

  return (
    <div className={styles.container}>
      <Tabs
        textColor="secondary"
        indicatorColor="secondary"
        className={styles.tabs}
        value={activeTab}
        onChange={handleActiveTab}
      >
        <Tab
          label={t("tabs.weekendsAndHolidays")}
          value="weekends_and_holidays"
        />
        <Tab label={t("vacationsSickDays.Vacations")} value="vacations" />
        <Tab
          disabled
          label={t("vacationsSickDays.SickLeave")}
          value="sick_leave"
        />
      </Tabs>
      {activeTab === "weekends_and_holidays" && (
        <Holidays />
      )}
      {activeTab === "vacations" && (
        <div>
          <Vacations />
        </div>
      )}
    </div>
  );
};
