import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import {
    getListOfPermissionsByUserId,
    getUsersAllPermissions,
    updateUserPermissions,
} from "store/thunks/usersPermissionsThunk";
import Loader from "ui/Loader";

import { Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
    BankCardIcon,
    CalendarIcon,
    CatalogsIcon,
    ContractsIcon,
    DisturbIcon,
    DocumentIcon,
    EmployeesIcon,
    ProjectsIcon,
    ResetIcon,
    SaveAs,
    SystemSettingsIcon,
    TrackingIcon,
    UsersIcon,
} from "../../../assets/icons";
import LeaveModal from "../../../features/LeaveModal/LeaveModal";
import { IAllPermissions } from "../../../models/user";
import { returnUserToInitialPermissions } from "../../../store";
import { rolesToolbarButtons } from "../../../styles/MUIStyles";
import { sendNotification } from "../../../ui/Toast";

import UsersPermissionsList from "./UsersPermissionsList";

import styles from "./UsersPermissions.module.scss";

interface Icon {
    [key: string]: React.ReactElement;
}

const iconMap: Icon = {
    Catalogs: <CatalogsIcon />,
    Organizations: <SystemSettingsIcon />,
    Administration: <UsersIcon />,
    Projects: <ProjectsIcon />,
    Salaries: <BankCardIcon />,
    Employees: <EmployeesIcon />,
    DaysOff: <CalendarIcon />,
    Tracking: <TrackingIcon />,
    Contracts: <ContractsIcon />,
    CommonDocuments: <DocumentIcon />,
    PersonalCabinet: <UsersIcon />,
};

const UserPermissionsSettings = () => {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const allPermissions = useAppSelector(
        (state) => state.users.allPermissions,
    );
    const usersPermissions = useAppSelector(
        (state) => state.users.usersPermissions,
    );
    const initialUsersPermissionsIds = useAppSelector(
        (state) => state.users.initialUsersPermissionsIds,
    );
    const isUpdatingPermissionsLoading = useAppSelector(
        (state) => state.users.isUpdatingPermissionsLoading,
    );
    const [isLoading, setIsLoading] = useState(false);

    // const isInitialPermissionsChanged = usersPermissions?.userPermissionIds.every(userPermissionId => initialUsersPermissionsIds.includes(userPermissionId));
    const userPermissionsSettingsUserName = localStorage.getItem(
        "userPermissionsSettingsUserName",
    );
    const [activeMenuItem, setActiveMenuItem] =
        useState<IAllPermissions | null>(null);
    const [modifiedAllPermissions, setModifiedAllPermissions] = useState<any>(
        [],
    );
    const [isPermissionUpdated, setIsPermissionUpdated] = useState(false);
    const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);

    const handleUpdateUserPermissions = async () => {
        if (usersPermissions && params?.id) {
            const updatedUserPermissions = await dispatch(
                updateUserPermissions({
                    userId: params.id,
                    permissionIds: usersPermissions.userPermissionIds,
                }),
            );

            if (updatedUserPermissions.meta.requestStatus === "fulfilled") {
                await dispatch(getListOfPermissionsByUserId(params.id));
                sendNotification(
                    t("notifications.successfullySaved"),
                    "success",
                );
                setIsPermissionUpdated(true);
            }
        }
    };

    const handleReturnUserToInitialPermissions = () => {
        dispatch(returnUserToInitialPermissions());
    };

    const goBack = () => {
        if (
            usersPermissions?.userPermissionIds.length !==
            initialUsersPermissionsIds.length
        ) {
            setIsLeaveModalOpen(true);
            return;
        }

        navigate(-1);
    };

    const handleLeaveModalClose = () => setIsLeaveModalOpen(false);

    useEffect(() => {
        (async () => {
            if (params && params.id) {
                setIsLoading(true);
                await Promise.all([
                    dispatch(getUsersAllPermissions()),
                    dispatch(getListOfPermissionsByUserId(params.id)),
                ]);
                setIsLoading(false);
            }
        })();
    }, [dispatch, params]);

    useEffect(() => {
        setModifiedAllPermissions(
            allPermissions.map((allPermission: any) => {
                return {
                    ...allPermission,
                    permissionGroups: allPermission.permissionGroups.map(
                        (permissionGroup: any) => ({
                            ...permissionGroup,
                            permissionCheckableActions:
                                permissionGroup.permissionActions.filter(
                                    (permissionAction: any) =>
                                        (initialUsersPermissionsIds.includes(
                                            permissionAction.id,
                                        ) ||
                                            !initialUsersPermissionsIds.includes(
                                                permissionAction.id,
                                            )) &&
                                        !usersPermissions?.userRolePermissionIds.includes(
                                            permissionAction.id,
                                        ),
                                ),
                        }),
                    ),
                };
            }),
        );
    }, [
        allPermissions,
        initialUsersPermissionsIds,
        usersPermissions?.userRolePermissionIds,
    ]);

    useEffect(() => {
        if (modifiedAllPermissions.length && !isPermissionUpdated) {
            setActiveMenuItem(modifiedAllPermissions[0]);
            setIsPermissionUpdated(false);
        }
    }, [modifiedAllPermissions]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <section>
            <Backdrop
                sx={{
                    backgroundColor: "rgba(0, 0, 0, .1)",
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isUpdatingPermissionsLoading}
            >
                <CircularProgress sx={{ color: "#6C6463" }} />
            </Backdrop>
            <div className={styles.navHeader}>
                <h3>{t("usersPermissions.users")}</h3>
            </div>
            <div className={styles.toolbar}>
                <Button
                    variant="text"
                    startIcon={<ResetIcon />}
                    onClick={handleReturnUserToInitialPermissions}
                    sx={rolesToolbarButtons}
                >
                    {t("buttons.Reset")}
                </Button>
                <Button
                    variant="text"
                    startIcon={<DisturbIcon />}
                    onClick={goBack}
                    sx={rolesToolbarButtons}
                >
                    {t("buttons.Close")}
                </Button>
                <Button
                    variant="text"
                    startIcon={<SaveAs />}
                    // disabled={isInitialPermissionsChanged}
                    onClick={handleUpdateUserPermissions}
                    sx={rolesToolbarButtons}
                >
                    {t("buttons.Save")}
                </Button>
            </div>
            <div className={styles.userSettingsTitle}>
                <h5>
                    {userPermissionsSettingsUserName
                        ? t("usersPermissions.accessPermissions") +
                          ":" +
                          " " +
                          userPermissionsSettingsUserName
                        : t("usersPermissions.accessPermissions")}
                </h5>
            </div>
            <div className={styles.userSettings}>
                <div className={styles.userSettingsSidebar}>
                    <ul className={styles.userSettingsSidebarList}>
                        {modifiedAllPermissions.map((permission: any) => {
                            return (
                                <li
                                    key={permission.id}
                                    className={
                                        activeMenuItem?.name === permission.name
                                            ? styles.userSettingsSidebarListItemActive
                                            : styles.userSettingsSidebarListItem
                                    }
                                    onClick={() =>
                                        setActiveMenuItem(permission)
                                    }
                                >
                                    {iconMap[permission.name]}
                                    <span>{t(`menu.${permission.name}`)}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className={styles.userSettingsPermissions}>
                    <UsersPermissionsList
                        activeMenuItem={activeMenuItem}
                        usersPermissions={usersPermissions}
                        initialUserPermissionIds={initialUsersPermissionsIds}
                    />
                </div>
                <LeaveModal
                    handleUpdatePermissions={handleUpdateUserPermissions}
                    handleReturnToInitialPermissions={
                        handleReturnUserToInitialPermissions
                    }
                    isLeaveModalOpen={isLeaveModalOpen}
                    handleLeaveModalClose={handleLeaveModalClose}
                />
            </div>
        </section>
    );
};

export default UserPermissionsSettings;
