import React, { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import { IFormData } from "models/tracking";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ErrorMessage } from "@hookform/error-message";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { TRACKING_PATH } from "../../../router/constants";
import {
  createAndUpdateTracking,
  getTrackingByEmployeeId,
  getTrackingById,
} from "../../../store/thunks/trackingThunk";
import { getDaysOffByEmployee } from "../../../store/thunks/vacationsSickDaysThunk";
import {
  activateOrDeactivateBtn as addDocumentBtn,
  cancelBtn,
} from "../../../styles/MUIStyles";
import ButtonWithProgress from "../../../ui/ButtonWithProgress";
import { sendNotification } from "../../../ui/Toast";

import styles from "../Tracking.module.scss";

const TrackingManagement: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IFormData>({
    defaultValues: {
      id: null,
      employeeId: "",
      projectId: 0,
      year: 0,
      month: 0,
      totalTime: 0,
      overTime: 0,
    },
    mode: "onSubmit",
  });

  const currentDate = new Date();
  const activeTracking = useAppSelector(
    (state) => state.tracking.activeTracking,
  );
  const editAndViewTrackingData = useAppSelector(
    (state) => state.tracking.editAndViewTrackingData,
  );


  const startDate = activeTracking?.startDate
    ? new Date(activeTracking.startDate)
    : null;

  const { daysOff } = useAppSelector(
    (state) => state.vacationsSickDays.daysOffByEmployee,
  );
  const { role } = useAppSelector((state) => state.auth);
  const { userId, userName } = useAppSelector((state) => state.auth);
  const [isView, setIsView] = useState<boolean>(false);
  const hasTotalTimeError = errors?.totalTime;
  const hasOverTimeError = errors?.overTime;


  const [year, setYear] = useLocalStorage<number>(
    "trackingFilterYear",
    currentDate.getFullYear(),
  );
  const [monthOfTracking, setMonthOfTracking] = useLocalStorage<number>(
    "trackingFilterMonth",
    currentDate.getMonth() + 1,
  );



  // @ts-ignore
  const filteredDaysOff = daysOff?.filter((vac) => {
    if (vac.numberOfDaysOff > 0) return vac;
  });

  const handleChangeYear = (newYear: number): void => {
    if(!startDate) return;

    const currentYear = currentDate.getFullYear();
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth() + 1;

    if (newYear >= startYear && newYear <= currentYear) {
        setYear(newYear);
        if (
            newYear === startYear &&
            monthOfTracking < startMonth
        ) {
            setMonthOfTracking(startMonth);
        }

        if(newYear === currentYear && monthOfTracking > currentDate.getMonth() + 1) {
          setMonthOfTracking(currentDate.getMonth() + 1);
        }
    }
};

const handleChangeMonth = (newMonth: number): void => {

    if(!startDate) return;

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth() + 1;

    if(year === startYear && newMonth < startMonth) {
      setMonthOfTracking(startMonth);
    } else if (year === currentYear && newMonth > currentMonth) {
        setMonthOfTracking(currentMonth);
    } else if(newMonth > 0 && newMonth <= 12) {
        setMonthOfTracking(newMonth);
    } else if (newMonth < 1) {
        if(year > startYear) {
            setYear(year - 1);
            setMonthOfTracking(12);
        }
    } else if(newMonth > 12) {
        if(year < currentYear) {
            setYear(year + 1);
            setMonthOfTracking(1);
        }
    }
};

  useEffect(() => {
    if (params.employeeId) {
      dispatch(getTrackingById(+params.employeeId));
    } else {
      dispatch(
        getTrackingByEmployeeId({
          employeeId: userId,
          year: year,
          month: monthOfTracking,
        }),
      );
    }
  }, [dispatch, params, monthOfTracking, userId, year]);


  const onSubmit = async (data: IFormData) => {
    if (params?.operation === "view") {
      navigate(
        `/tracking/management/edit/${editAndViewTrackingData?.timekeepingId}`,
      );
      setIsView(false); // Добавляем это здесь
      // navigate(`management/edit/${props.data.projectId}`)
    }

    if (params?.operation === "edit" && editAndViewTrackingData) {
      const response = await dispatch(
        createAndUpdateTracking({
          id: editAndViewTrackingData?.timekeepingId || null,
          employeeId: userId,
          projectId: editAndViewTrackingData.projectId,
          year: year,
          month: monthOfTracking,
          totalTime: parseInt((data.totalTime || 0).toString()),
          overtime: parseInt((data.overTime || 0).toString()),
        }),
      );

      if (response.meta.requestStatus === "fulfilled") {
        sendNotification(t("notifications.successfullySaved"), "success");
        reset(editAndViewTrackingData);
        navigate(-1);
        setIsView(false);
      }
      if (response.meta.requestStatus === "rejected") {
        sendNotification(t("notifications.failedToSave"), "error");
      }
    }
  };

  const mapBackendDataToForm = (data: any): IFormData => ({
    id: data?.timekeepingId || null,
    employeeId: userId || "",
    projectId: data?.projectId || 0,
    year: year || 0,
    month: monthOfTracking || 0,
    totalTime: data?.totalTime ?? 0,
    overTime: 0,
  });

  useEffect(() => {
    if (editAndViewTrackingData) {
      reset(mapBackendDataToForm(editAndViewTrackingData));
    }
    if (
      (params?.operation === "edit" || params?.operation === "view") &&
      editAndViewTrackingData
    ) {
      if (params?.operation === "view") {
        setIsView(true);
      }
      reset(editAndViewTrackingData);
    }
  }, [params, editAndViewTrackingData, reset, activeTracking]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [dispatch, isSubmitSuccessful, reset, params]);

  useEffect(() => {
    dispatch(
      getDaysOffByEmployee({
        year: year,
        employeeId: userId || "",
        role,
        month: monthOfTracking || "",
      }),
    );
  }, [dispatch, userId, year, monthOfTracking]);

  return (
    <div style={{ height: "92.5vh", overflow: "auto" }}>
      <Box sx={{ width: "100%" }}>
        <Breadcrumbs sx={{ padding: "10px 10px 10px 35px" }}>
          <Link to={"/" + TRACKING_PATH} className={styles.breadScrumbsLink}>
            {t("menu.Tracking")}
          </Link>
          <Typography sx={{ color: "#201f1e", fontFamily: "Source Sans Pro" }}>
            {params?.operation === "create" && ""}
            {(params?.operation === "edit" || params?.operation === "view") &&
              userName}
          </Typography>
        </Breadcrumbs>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className={styles.formWrapper}>
              <React.Fragment>
                <div className={styles.projectNameWrapper}>
                  <Box>
                    <Typography
                      sx={{
                        color: "#BEBEBE",
                        fontWeight: 600,
                        width: "50%",
                        fontFamily: "Source Sans Pro",
                        fontSize: "18px",
                      }}
                    >
                      {params?.operation === "create" && ""}
                      {params?.operation === "edit" && ("Трекинг" || "Трекинг")}
                    </Typography>
                    <h3 className={styles.projectName}>
                      {params?.operation === "create" && ""}
                      {(params?.operation === "edit" ||
                        params?.operation === "view") &&
                        userName}
                    </h3>
                  </Box>

                  <div className={styles.generalInfoTopBtns}>
                    <ButtonWithProgress
                      variant="contained"
                      type="submit"
                      loading={false}
                      disabled={false}
                      sx={{ ...addDocumentBtn }}
                    >
                      {params?.operation === "edit"
                        ? t("buttons.Save")
                        : t("buttons.Edit")}
                    </ButtonWithProgress>
                    <Button
                      variant="text"
                      sx={cancelBtn}
                      onClick={() => {
                        navigate("/tracking");
                      }}
                    >
                      {t("buttons.Close")}
                    </Button>
                  </div>
                </div>

                <div className={styles.formInnerWrapper}>
                  <div className={styles.box_year}>
                    <ArrowLeftIcon
                      htmlColor="gray"
                      sx={{
                        color: "green",
                        fontSize: "40px",
                      }}
                      onClick={() => handleChangeYear(year - 1)}
                    />
                    <p>{year}</p>
                    <ArrowRightIcon
                      htmlColor="gray"
                      sx={{
                        color:
                          year === currentDate.getFullYear() ? "gray" : "green",
                        fontSize: "40px",
                      }}
                      onClick={() => handleChangeYear(year + 1)}
                    />
                  </div>
                  <div className={styles.box_year}>
                    <ArrowLeftIcon
                      htmlColor="gray"
                      sx={{
                        color: "green",
                        fontSize: "40px",
                      }}
                      onClick={() => handleChangeMonth(monthOfTracking - 1)}
                    />
                    <p>{t(`months.${monthOfTracking}`)}</p>
                    <ArrowRightIcon
                      htmlColor="gray"
                      sx={{
                        color:
                          monthOfTracking === currentDate.getMonth() + 1
                            ? "gray"
                            : "green",
                        fontSize: "40px",
                      }}
                      onClick={() => handleChangeMonth(monthOfTracking + 1)}
                    />
                  </div>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "1rem 0",
                      borderBottom: "2px solid #BEBEBE",
                    }}
                  >
                    {!!editAndViewTrackingData?.projectCode && (
                      <Typography
                        sx={{
                          color: "#BEBEBE",
                          fontWeight: 600,
                          fontFamily: "Source Sans Pro",
                          fontSize: "18px",
                        }}
                      >
                        {editAndViewTrackingData.projectCode}
                      </Typography>
                    )}
                    {!!editAndViewTrackingData?.shortName && (
                      <Typography
                        sx={{
                          color: "#BEBEBE",
                          fontWeight: 600,
                          fontFamily: "Source Sans Pro",
                          fontSize: "18px",
                          marginRight: "auto",
                          marginLeft: "12px",
                        }}
                      >
                        {editAndViewTrackingData.shortName}
                      </Typography>
                    )}
                  </Box>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4} sx={{ textAlign: "right" }}>
                      <label htmlFor="totalTime">
                        <b>Всего часов</b>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={3} sx={{ textAlign: "center" }}>
                      <input
                        {...register("totalTime", {
                          required:
                            params?.operation !== "view"
                              ? (t("validation.requiredField") as string)
                              : false,
                        })}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          if (Number(target.value) < 0) {
                            target.value = "0";
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        disabled={isView}
                        style={{ textAlign: "center" }}
                        id="totalTime"
                        type="number"
                        defaultValue={0}
                        autoComplete="off"
                        className={
                          hasTotalTimeError
                            ? styles.projectCodeInputErrorError
                            : styles.projectCodeInput
                        }
                      />
                      <ErrorMessage
                        name={"totalTime"}
                        errors={errors}
                        render={({ message }) => <span>{message}</span>}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}></Grid>
                  </Grid>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4} sx={{ textAlign: "right" }}>
                      <label htmlFor="overTime">
                        <b>Из них сверхурочно</b>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={3} sx={{ textAlign: "center" }}>
                      <input
                        {...register("overTime", {
                          required:
                            params?.operation !== "view"
                              ? (t("validation.requiredField") as string)
                              : false,
                        })}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          if (Number(target.value) < 0) {
                            target.value = "0";
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        disabled={isView}
                        style={{ textAlign: "center" }}
                        id="overTime"
                        type="number"
                        defaultValue={0}
                        autoComplete="off"
                        className={
                          hasOverTimeError
                            ? styles.projectCodeInputErrorError
                            : styles.projectCodeInput
                        }
                      />
                      <ErrorMessage
                        name={"overTime"}
                        errors={errors}
                        render={({ message }) => <span>{message}</span>}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}></Grid>
                  </Grid>
                </div>
              </React.Fragment>
            </div>
          </div>
        </form>
      </Box>
    </div>
  );
};

export default TrackingManagement;
