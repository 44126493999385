import React, { useEffect } from "react";
import Guard from "features/Guard";
import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DAYSOFF_PATH } from "router/constants";
import {
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
} from "store";
import { getDaysOffByEmployee } from "store/thunks/vacationsSickDaysThunk";
import { cancelBtn, saveBtn } from "styles/MUIStyles";

import { Backdrop, Breadcrumbs, Button, CircularProgress } from "@mui/material";

import DaysOffViewEmployeeCard from "./DaysOffViewEmployeeCard/DaysOffViewEmployeeCard";

import styles from "./VacationsSickDaysViewEmployee.module.scss";

const VacationsSickDaysViewEmployee = () => {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const { role } = useAppSelector((state) => state.auth);
    const isGettingDaysOffByEmployeeLoding = useAppSelector(
        (state) => state.vacationsSickDays.isGettingDaysOffByEmployeeLoding,
    );
    const { fullName, personnelNumber, vacationBalance, daysOff } = useAppSelector(
        (state) => state.vacationsSickDays.daysOffByEmployee,
    );

    useEffect(() => {
        dispatch(getDaysOffByEmployee({ ...params, role } as any)); //fixed any
    }, [dispatch, params]);

    const filtredBySignDaysOff = (sign: any) => {
        return daysOff?.filter((item: any) => item.sign === sign);
    };

    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);

    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === "DaysOff");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [allowedMenus, dispatch]);

    useEffect(() => {
        const group = allowedGroups.find((menu) => menu.name === `api/daysOff`);
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    return (
        <div className={styles.container}>
            <Breadcrumbs>
                <Link to="/" className={styles.link_breadcrumbs}>
                    Impulse
                </Link>
                <Link
                    to={`/${DAYSOFF_PATH}`}
                    className={styles.link_breadcrumbs}
                >
                    {t("vacationsSickDays.VacationsAndSickDays")}
                </Link>
                <p>{t("buttons.View")}</p>
            </Breadcrumbs>
            <div className={styles.boxTitle}>
                <h2>
                    {t("vacationsSickDays.VacationsAndSickDays")} {params.year}
                </h2>
                <div>
                    <Guard
                        allowedPermissions={allowedPermissions}
                        permissionName="Create"
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            sx={saveBtn}
                            onClick={() => navigate("edit")}
                        >
                            {t("buttons.Edit")}
                        </Button>
                    </Guard>

                    <Button
                        variant="text"
                        sx={cancelBtn}
                        onClick={() => navigate(`/${DAYSOFF_PATH}`)}
                    >
                        {t("buttons.Close")}
                    </Button>
                </div>
            </div>
            <h3 className={styles.subtitle_name}>
                {fullName} {t("data.tabNo")} {personnelNumber || "-"}
            </h3>
            <div className={styles.containerCards}>
                <DaysOffViewEmployeeCard
                    title={t("vacationsSickDays.Vacations")}
                    vacationBalance={vacationBalance}
                    daysOff={filtredBySignDaysOff(1)}
                />
                <DaysOffViewEmployeeCard
                    title={t("vacationsSickDays.SickLeave")}
                    daysOff={filtredBySignDaysOff(2)}
                />
                <DaysOffViewEmployeeCard 
                    title={t("vacationsSickDays.DaysOff")}
                    daysOff={filtredBySignDaysOff(3)}
                />
            </div>
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isGettingDaysOffByEmployeeLoding}
            >
                <CircularProgress sx={{ color: "#fff" }} />
            </Backdrop>
        </div>
    );
};

export default VacationsSickDaysViewEmployee;
