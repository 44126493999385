// Personal account
export const PERSONAL_ACCOUNT = 'personalcabinet';
export const PERSONAL_ACCOUNT_EDIT = 'personalcabinet/edit';
export const PERSONAL_ACCOUNT_COMMON_INFO = "common-info";
export const PERSONAL_ACCOUNT_DOCUMENTS = "personal-documents";
export const PERSONAL_ACCOUNT_CALENDAR = "calendar";
export const PERSONAL_ACCOUNT_APPLICATIONS = "applications";
export const PERSONAL_ACCOUNT_NOTIFICATIONS = "notifications";
// Administration
export const ADMINISTRATION_PATH: string = 'administration';
export const IMPORT_PATH: string = 'import';
export const ROLES_PERMISSIONS_PATH: string = 'roles/permissions';
export const ROLE_PERMISSIONS_PATH: string = 'roles/permissions/:roleName';
export const USERS_PERMISSIONS_PATH: string = 'users/permissions';
export const USER_PERMISSIONS_PATH: string = 'users/permissions/:id';

export const LOGIN_PATH: string = 'login';
export const CATALOG_WRAPPER_PATH: string = 'catalogs';
export const CATALOG_PATHS: string[] = ['typeOfEmployments', 'typeOfContracts', 'guilds', 'positions', 'regions', 'skillsets', 'timebelts', 'departments', 'costCenters', 'grades', 'currencies', 'countries', 'skills', 'skilllevels', 'currencyRates'];
export const ORGANIZATIONS_PATH: string = 'organizations';
export const ORGANIZATION_PATH: string = ':organizationId';
export const ORGANIZATION_EDITING_PATH: string = 'edit/:id';
export const ORGANIZATION_CREATE_PATH: string = 'create';
export const USERS_PATH: string = 'users';
export const EMPLOYEES_PATH: string = 'employees';
export const EMPLOYEE_PATH: string = ':employeeId';
export const EMPLOYEE_EDITING_PATH: string = 'edit/:id';
export const PROJECTS_PATH: string = 'projects';
export const PROJECT_PATH: string = 'view/:projectId';
export const PROJECTS_MANAGEMENT_PATH: string = 'management/:operation';
export const PROJECT_MANAGEMENT_PATH: string = 'management/:operation/:projectId';
export const SALARIES_PATH: string = 'salaries';
// export const DAYS_OFF_PATH : string = "daysOff";
export const DAYSOFF_PATH: string = 'daysOff';
export const DAYSOFF_PATH_REMAINING_DAYS: string = 'vacationdaysleft';
export const DAYSOFF_EMPLOYEE_PATH: string = ':year/:employeeId';
export const TRACKING_PATH: string = 'tracking';

//временно, бэк должен исправить
export const CONTRACTS_PATH: string = 'contracts';
export const CONTRACT_EMPLOYEES_PATH: string = 'contracts';
export const CONTRACTS_VIEW_DETAILS_PATH: string = 'view/details/:contractId';
export const CONTRACTS_CREATE: string = 'management/create'; //может быть в тип вынести и оттуда брать, чтоб понятно было какие операции могут быть..?
export const CONTRACTS_EDIT: string = 'management/edit/:contractId';
export const CONTRACTS_VIEW: string = 'view/:contractId';
export const CONTRACTS_DETAILS_CREATE: string = 'details/management/:operation';
export const CONTRACTS_DETAILS_EDIT: string = 'details/management/:operation/:contractId';
export const TRACKING_VIEW_PATH: string = 'view/:trackingId';
export const TRACKING_EMPLOYEE_TABLE_VIEW_PATH: string = 'employee/view/:employeeId';
export const TRACKINGS_MANAGMENT_PATH: string = 'management/:operation';
export const TRACKING_MANAGEMENT_PATH: string = 'management/:operation/:employeeId';
