import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { CreateIcon, EditIcon } from "assets/icons";
import ConfirmationModal from "features/ConfirmationModal";
import Guard from "features/Guard";
import {
    useAppDispatch,
    useAppSelector,
    useDebounce,
    useLocalStorage,
    useSortColumn,
} from "hooks";
import { IConfirmationModal, IUser } from "models/user";
import { useTranslation } from "react-i18next";
import {
    blockUser,
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
    getCountries,
    getUserRoles,
    getUsers,
    setSearchUser,
    unblockUser,
} from "store";
import {
    createOrEditBtn,
    resetDefaultSelectBackground,
    selectWithCloseIcon,
} from "styles/MUIStyles";
import { sendNotification } from "ui/Toast";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputLabel } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";

import CreateUserForm from "./CreateUserForm";
import UpdateUserForm from "./UpdateUserForm";
import UsersTable from "./UsersTable";

import usersStyles from "./Users.module.scss";

const Users = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const searchUser = useAppSelector((state) => state.users.searchUser);
    const totalCount = useAppSelector((state) => state.users.totalCount);
    const isUsersLoading = useAppSelector(
        (state) => state.users.isUsersLoading,
    );

    const isUserSearchingRef = useRef<boolean>(false);
    const [activeUser, setActiveUser] = useState<null | IUser>(null);
    const [pageSize, setPageSize] = useLocalStorage<number>(
        "usersPageSize",
        10,
    );
    const [pageNumber, setPageNumber] = useLocalStorage("usersPageNumber", 1);
    const [createOrUpdate, setCreateOrUpdate] = useState<null | string>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [userStatus, setUserStatus] = useLocalStorage("userStatus", "");
    const debouncedSearchUserName = useDebounce(searchUser.userName);
    const debouncedSearchUserEmail = useDebounce(searchUser.email);
    const { sortConfig, handleColumnClick } = useSortColumn(
        "usersTableSortConfig",
    );
    const [confirmationModalOpen, setConfirmationModalOpen] =
        useState<IConfirmationModal>({
            isOpen: false,
            actionName: "",
        });

    const activateItem = async () => {
        if (activeUser?.id) {
            const response = await dispatch(unblockUser(activeUser.id));

            if (response.meta.requestStatus === "fulfilled") {
                sendNotification(
                    t("notifications.successfullyUnblocked"),
                    "success",
                );
                await dispatch(
                    getUsers({
                        userName: debouncedSearchUserName,
                        email: debouncedSearchUserEmail,
                        pageNumber,
                        userStatus,
                        pageSize,
                        sortBy: sortConfig,
                    }),
                );
                setConfirmationModalOpen({ isOpen: false, actionName: "" });
            }
        }
    };

    const deactivateItem = async () => {
        if (activeUser?.id) {
            try {
                const response = await dispatch(blockUser(activeUser.id));
    
                if (response.meta.requestStatus === "fulfilled") {
                    sendNotification(
                        t("notifications.successfullyBlocked"),
                        "success",
                    );
                    await dispatch(
                        getUsers({
                            userName: debouncedSearchUserName,
                            email: debouncedSearchUserEmail,
                            pageNumber,
                            userStatus,
                            pageSize,
                            sortBy: sortConfig,
                        }),
                    );
                } else {
                    const errorMessage =
                        response.payload?.error || t("notifications.userWithEmailNotFound");
                    sendNotification(errorMessage, "error");
                }
            } catch (error) {
                console.log(error);
                sendNotification(
                    t("notifications.failedToBlock"),
                    "error",
                );
                
            } finally {
                setConfirmationModalOpen({ isOpen: false, actionName: "" });
            }
        }
    };
    

    const confirmActionHandler = () => {
        switch (confirmationModalOpen.actionName) {
            case "Unblock":
                activateItem();
                break;
            case "Block":
                deactivateItem();
                break;
        }
    };

    const onCreateUser = () => {
        setIsDrawerOpen(true);
        setCreateOrUpdate("create");
    };

    const handleUserStatusChange = (event: SelectChangeEvent) => {
        setUserStatus(event.target.value as string);
        setPageNumber(1);
    };

    const handleSearchUser = (e: any) => {
        isUserSearchingRef.current = true;
        const { name, value } = e.target;
        dispatch(setSearchUser({ name, value }));
    };

    const handleOnPageChange = (event: ChangeEvent<unknown>, page: number) => {
        setActiveUser(null);
        setPageNumber(page);
    };

    const onEditUser = () => {
        setIsDrawerOpen(true);
        setCreateOrUpdate("update");
    };

    const handlePageSizeChange = (event: SelectChangeEvent) => {
        setPageSize(event.target.value);
        setPageNumber(1);
        dispatch(
            getUsers({
                userName: searchUser.userName,
                email: searchUser.email,
                pageNumber: 1,
                userStatus,
                pageSize: +event.target.value,
                sortBy: sortConfig,
            }),
        );
    };

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === "Users");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [dispatch, allowedMenus]);

    useEffect(() => {
        const group = allowedGroups.find((menu) => menu.name === "api/users");
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    useEffect(() => {
        dispatch(getCountries("countries"));
        dispatch(getUserRoles());
    }, [dispatch, t]);

    return (
        <div>
            <div className={usersStyles.crudButtonWrapper}>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Create"
                >
                    <Button
                        startIcon={<CreateIcon />}
                        variant="text"
                        disabled={false}
                        onClick={() => onCreateUser()}
                        sx={createOrEditBtn}
                    >
                        {t("buttons.Create")}
                    </Button>
                </Guard>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Edit"
                >
                    <Button
                        startIcon={<EditIcon />}
                        variant="text"
                        disabled={!activeUser}
                        onClick={() => onEditUser()}
                        sx={createOrEditBtn}
                    >
                        {t("buttons.Edit")}
                    </Button>
                </Guard>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: ".7rem",
                        marginLeft: "auto",
                        marginRight: "2rem",
                    }}
                >
                    <span>{t("filters.elementsOnPage")}</span>
                    <FormControl variant="standard">
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={pageSize}
                            onChange={handlePageSizeChange}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </div>
            <Box sx={{ display: "flex", gap: "10px", width: "70%" }}>
                <div className={usersStyles.searchWrapper}>
                    <input
                        type="text"
                        name="userName"
                        autoComplete="off"
                        value={searchUser.userName}
                        onChange={handleSearchUser}
                        placeholder={
                            t("filters.searchByNameAndSurname") as string
                        }
                        className={usersStyles.search}
                    />
                    <button
                        type="button"
                        className={usersStyles.searchIconWrapper}
                    >
                        <SearchIcon sx={{ color: "#fff" }} />
                    </button>
                </div>
                <div className={usersStyles.searchWrapper}>
                    <input
                        type="text"
                        name="email"
                        autoComplete="off"
                        value={searchUser.email}
                        onChange={handleSearchUser}
                        placeholder={t("filters.searchByEmail") as string}
                        className={usersStyles.search}
                    />
                    <button
                        type="button"
                        className={usersStyles.searchIconWrapper}
                    >
                        <SearchIcon sx={{ color: "#fff" }} />
                    </button>
                </div>
                <FormControl fullWidth>
                    {userStatus ? null : (
                        <InputLabel
                            shrink={false}
                            sx={{
                                transform: "translate(1px, 1px)",
                                fontSize: "1rem",
                                padding: "0.450rem 0.95rem",
                            }}
                        >
                            {t("filters.selectStatus")}
                        </InputLabel>
                    )}
                    <Select
                        value={userStatus}
                        sx={selectWithCloseIcon}
                        onChange={handleUserStatusChange}
                        endAdornment={
                            <IconButton onClick={() => setUserStatus("")}>
                                {userStatus ? (
                                    <ClearIcon
                                        sx={{ width: "19px", height: "19px" }}
                                    />
                                ) : null}
                            </IconButton>
                        }
                    >
                        <MenuItem value={1} sx={resetDefaultSelectBackground}>
                            {t("filters.active")}
                        </MenuItem>
                        <MenuItem value={2} sx={resetDefaultSelectBackground}>
                            {t("filters.inactive")}
                        </MenuItem>
                        <MenuItem value={3} sx={resetDefaultSelectBackground}>
                            {t("filters.markedForDeletion")}
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <UsersTable
                sortConfig={sortConfig}
                activeUser={activeUser}
                setActiveUser={setActiveUser}
                onEditUser={onEditUser}
                handleColumnClick={handleColumnClick}
                setConfirmationModalOpen={setConfirmationModalOpen}
                isUserSearchingRef={isUserSearchingRef}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                debouncedSearchUserName={debouncedSearchUserName}
                debouncedSearchUserEmail={debouncedSearchUserEmail}
                userStatus={userStatus}
                pageSize={pageSize}
            />
            <Stack sx={{ width: "500px", margin: "12px auto 0 auto" }}>
                <Pagination
                    sx={{ margin: "auto" }}
                    count={
                        totalCount
                            ? Math.ceil(totalCount / Number(pageSize))
                            : 1
                    }
                    page={pageNumber}
                    size="medium"
                    onChange={handleOnPageChange}
                    shape="rounded"
                    color="primary"
                    variant="outlined"
                />
            </Stack>
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isUsersLoading}
            >
                <CircularProgress sx={{ color: "#fff" }} />
            </Backdrop>
            {createOrUpdate === "create" ? (
                <CreateUserForm
                    sortConfig={sortConfig}
                    createOrUpdate={createOrUpdate}
                    setIsDrawerOpen={setIsDrawerOpen}
                    isDrawerOpen={isDrawerOpen}
                    setCreateOrUpdate={setCreateOrUpdate}
                    pageNumber={pageNumber}
                    pageSize={pageSize}
                    debouncedSearchUserName={debouncedSearchUserName}
                    debouncedSearchUserEmail={debouncedSearchUserEmail}
                    userStatus={userStatus}
                />
            ) : (
                <UpdateUserForm
                  sortConfig={sortConfig}
                    createOrUpdate={createOrUpdate}
                    setIsDrawerOpen={setIsDrawerOpen}
                    isDrawerOpen={isDrawerOpen}
                    activeUser={activeUser}
                    setActiveUser={setActiveUser}
                    setCreateOrUpdate={setCreateOrUpdate}
                    pageNumber={pageNumber}
                    pageSize={pageSize}
                    debouncedSearchUserName={debouncedSearchUserName}
                    debouncedSearchUserEmail={debouncedSearchUserEmail}
                    userStatus={userStatus}
                />
            )}
            <ConfirmationModal
                isModalOpen={confirmationModalOpen.isOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={confirmationModalOpen.actionName}
                instanceName={"users"}
                handleAction={confirmActionHandler}
            />
        </div>
    );
};

export default Users;
