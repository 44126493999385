import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
    ISearchUserPayload,
    IUserSlice,
    PermissionAction,
} from "../../models/user";
import { revertAll } from "../store";
import {
    changeUserEmail,
    createUser,
    editUser,
    getUserRoles,
    getUsers,
} from "../thunks";
import {
    getAllPermissions,
    getListOfPermissionsByRoleName,
    updateRolePermissions,
} from "../thunks/rolesThunk";
import {
    getAllUsers,
    getListOfPermissionsByUserId,
    getUsersAllPermissions,
    updateUserPermissions,
} from "../thunks/usersPermissionsThunk";

const initialState: IUserSlice = {
    isLoading: false,
    isUsersLoading: false,
    isUserRolesLoading: false,
    users: [],
    roles: [],
    userErrors: null,
    allPermissions: [],
    initialRolePermissions: [],
    rolePermissions: [],
    usersPermissions: null,
    initialUsersPermissionsIds: [],
    isUpdatingPermissionsLoading: false,
    isUserEmailChangingLoading: false,
    isGettingAllUsersLoading: false,
    usersPermissionsList: null,
    totalCount: null,
    searchUser: {
        userName: "",
        email: "",
    },
};

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        clearUserTextFieldErrors: (state) => {
            state.userErrors = null;
        },
        handlePermissionsRoleParentCheckbox: (state, action) => {
            if (action.payload.checked) {
                state.rolePermissions = Array.from(
                    new Set([
                        ...state.rolePermissions,
                        ...action.payload.permissionActions.map(
                            (permissionAction: PermissionAction) => {
                                return permissionAction.id;
                            },
                        ),
                    ]),
                );
            } else {
                state.rolePermissions = Array.from(
                    new Set(
                        state.rolePermissions.filter(
                            (rolePermission) =>
                                !action.payload.permissionActions.some(
                                    (permissionAction: PermissionAction) =>
                                        permissionAction.id === rolePermission,
                                ),
                        ),
                    ),
                );
            }
        },
        handlePermissionsRoleChildCheckbox: (state, action) => {
            if (action.payload.checked) {
                state.rolePermissions = Array.from(
                    new Set([
                        ...state.rolePermissions,
                        action.payload.permissionId,
                    ]),
                );
            } else {
                state.rolePermissions = state.rolePermissions.filter(
                    (rolePermission) =>
                        rolePermission !== action.payload.permissionId,
                );
            }
        },
        returnRoleToInitialPermissions: (state) => {
            state.rolePermissions = state.initialRolePermissions;
        },
        returnUserToInitialPermissions: (state) => {
            if (state.usersPermissions) {
                state.usersPermissions.userPermissionIds =
                    state.initialUsersPermissionsIds;
            }
        },
        handlePermissionsUsersParentCheckbox: (state, action) => {
            if (state.usersPermissions) {
                if (action.payload.checked && state.usersPermissions) {
                    state.usersPermissions.userPermissionIds = Array.from(
                        new Set([
                            ...state.usersPermissions.userPermissionIds,
                            ...action.payload.permissionActions.map(
                                (permissionAction: PermissionAction) => {
                                    return permissionAction.id;
                                },
                            ),
                        ]),
                    );
                } else {
                    state.usersPermissions.userPermissionIds = Array.from(
                        new Set(
                            state.usersPermissions.userPermissionIds.filter(
                                (userPermissionId) =>
                                    !action.payload.permissionActions.some(
                                        (permissionAction: PermissionAction) =>
                                            permissionAction.id ===
                                            userPermissionId,
                                    ),
                            ),
                        ),
                    );
                }
            }
        },
        handlePermissionsUsersChildCheckbox: (state, action) => {
            if (state.usersPermissions) {
                if (action.payload.checked) {
                    state.usersPermissions.userPermissionIds = Array.from(
                        new Set(
                            state.usersPermissions.userPermissionIds.filter(
                                (userPermissionId) =>
                                    userPermissionId !==
                                    action.payload.permissionId,
                            ),
                        ),
                    );
                } else {
                    state.usersPermissions.userPermissionIds = Array.from(
                        new Set([
                            ...state.usersPermissions.userPermissionIds,
                            action.payload.permissionId,
                        ]),
                    );
                }
            }
        },
        setSearchUser: (state, action: PayloadAction<ISearchUserPayload>) => {
            const { name, value } = action.payload;
            state.searchUser[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(revertAll, () => initialState)
            .addCase(getUsers.pending, (state) => {
                state.isUsersLoading = true;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.users = [...action.payload.data].sort((a, b) => {
                    const nameA = a.firstName?.toUpperCase();
                    const nameB = b.firstName?.toUpperCase();
                    const lastNameA = a.lastName?.toUpperCase();
                    const lastNameB = b.lastName?.toUpperCase();

                    if (nameA < nameB) {
                        return -1;
                    } else if (nameA > nameB) {
                        return 1;
                    } else {
                        if (lastNameA < lastNameB) {
                            return -1;
                        } else if (lastNameA > lastNameB) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                });
                state.isUsersLoading = false;
                state.totalCount = action.payload.metadata.totalItemCount;
            })
            .addCase(getUsers.rejected, (state) => {
                state.isUsersLoading = false;
            })
            .addCase(getUserRoles.pending, (state) => {
                state.isUserRolesLoading = true;
            })
            .addCase(getUserRoles.fulfilled, (state, action) => {
                state.roles = action.payload;
                state.isUserRolesLoading = false;
            })
            .addCase(getUserRoles.rejected, (state) => {
                state.isUserRolesLoading = false;
            })
            .addCase(createUser.rejected, (state, action) => {
                if (action.payload) state.userErrors = action.payload;
            })
            .addCase(getAllPermissions.fulfilled, (state, action) => {
                state.allPermissions = action.payload;
            })
            .addCase(
                getListOfPermissionsByRoleName.fulfilled,
                (state, action) => {
                    state.rolePermissions = action.payload;
                    state.initialRolePermissions = action.payload;
                },
            )
            .addCase(editUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editUser.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(editUser.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(updateRolePermissions.pending, (state) => {
                state.isUpdatingPermissionsLoading = true;
            })
            .addCase(updateRolePermissions.fulfilled, (state) => {
                state.isUpdatingPermissionsLoading = false;
            })
            .addCase(updateRolePermissions.rejected, (state) => {
                state.isUpdatingPermissionsLoading = false;
            })
            .addCase(changeUserEmail.pending, (state) => {
                state.isUserEmailChangingLoading = true;
            })
            .addCase(changeUserEmail.fulfilled, (state) => {
                state.isUserEmailChangingLoading = false;
            })
            .addCase(changeUserEmail.rejected, (state) => {
                state.isUserEmailChangingLoading = false;
            })
            .addCase(getAllUsers.pending, (state) => {
                state.isGettingAllUsersLoading = true;
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.isGettingAllUsersLoading = false;
                state.usersPermissionsList = action.payload;
            })
            .addCase(getAllUsers.rejected, (state) => {
                state.isGettingAllUsersLoading = false;
            })
            .addCase(getUsersAllPermissions.fulfilled, (state, action) => {
                state.allPermissions = action.payload;
            })
            .addCase(
                getListOfPermissionsByUserId.fulfilled,
                (state, action) => {
                    state.usersPermissions = action.payload;
                    state.initialUsersPermissionsIds =
                        action.payload.userPermissionIds;
                },
            )
            .addCase(updateUserPermissions.pending, (state) => {
                state.isUpdatingPermissionsLoading = true;
            })
            .addCase(updateUserPermissions.fulfilled, (state) => {
                state.isUpdatingPermissionsLoading = false;
            })
            .addCase(updateUserPermissions.rejected, (state) => {
                state.isUpdatingPermissionsLoading = false;
            });
    },
});

export const {
    clearUserTextFieldErrors,
    handlePermissionsRoleChildCheckbox,
    handlePermissionsRoleParentCheckbox,
    returnRoleToInitialPermissions,
    returnUserToInitialPermissions,
    handlePermissionsUsersParentCheckbox,
    handlePermissionsUsersChildCheckbox,
    setSearchUser,
} = usersSlice.actions;

export default usersSlice.reducer;
