import React, { useEffect } from "react";
import {
    BankCardIcon,
    CalendarIcon,
    CatalogsIcon,
    ContractsIcon,
    DocumentIcon,
    EmployeesIcon,
    ExpandMoreIcon,
    ProjectsIcon,
    SystemSettingsIcon,
    TrackingIcon,
    UsersIcon,
} from "assets/icons";
import { useAppDispatch, useAppSelector } from "hooks";
import { PermissionGroup } from "models/user";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { handleNavBarExpanded, setPageWithMenuGroups } from "store";
import { getAllowedGroupsByMenuId } from "store/thunks/authThunk";

import { Accordion, Box, CircularProgress, Typography } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import {
    navBarAccordion,
    navBarAccordionSummary,
    personalMenuBtn,
} from "../../../styles/MUIStyles";

interface Icon {
    [key: string]: React.ReactElement;
}

const iconMap: Icon = {
    Employees: <EmployeesIcon />,
    Catalogs: <CatalogsIcon />,
    CommonDocuments: <DocumentIcon />,
    Organizations: <SystemSettingsIcon />,
    Administration: <UsersIcon />,
    Projects: <ProjectsIcon />,
    Salaries: <BankCardIcon />,
    // VacationsSickDays: <CalendarIcon />,
    DaysOff: <CalendarIcon />,
    Tracking: <TrackingIcon />,
    Contracts: <ContractsIcon />,
};

const NavBar = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const currentLanguage = localStorage.getItem("i18nextLng");
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);
    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const isAllowedGroupsLoading = useAppSelector(
        (state) => state.auth.isAllowedGroupsLoading,
    );
    const expanded = useAppSelector((state) => state.auth.expanded);
    const pageWithMenuGroups = useAppSelector(
        (state) => state.auth.pageWithMenuGroups,
    );

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            dispatch(handleNavBarExpanded(isExpanded ? panel : false));
        };

    const getPageName = (text: string) => {
        const newStr = text.replace("api/", "");

        if (newStr.includes("/")) {
            return newStr.replace("/", "");
        }
        return newStr;
    };

    const getMenuItemsByGroup = async () => {
        if (pageWithMenuGroups) {
            const pagesGroupsMenuToFetch = [
                "Administration",
                "Contracts",
                "Catalogs",
            ];
            const menu = allowedMenus.find(
                (menu) =>
                    pageWithMenuGroups &&
                    menu.name === pageWithMenuGroups &&
                    pagesGroupsMenuToFetch.includes(pageWithMenuGroups),
            );
            if (!menu) return;
            menu?.id >= 0 && dispatch(getAllowedGroupsByMenuId(menu.id));
        }
    };

    useEffect(() => {
        getMenuItemsByGroup();
    }, [dispatch, allowedMenus, pageWithMenuGroups]);

    useEffect(() => {
        if (localStorage.getItem("pageWithMenuGroups")) {
            dispatch(
                setPageWithMenuGroups(
                    localStorage.getItem("pageWithMenuGroups"),
                ),
            );
        }
    }, []);

    const personalAccount = allowedMenus.find(
        (menu) => menu.name === "PersonalCabinet",
    );
    const otherMenus = allowedMenus.filter(
        (menu) => menu.name !== "PersonalCabinet",
    );

    return (
        <>
            <nav style={{ marginBottom: "10px" }}>
                {personalAccount && (
                    <NavLink
                        to={personalAccount.name.toLowerCase()}
                        onClick={() => {
                            dispatch(handleNavBarExpanded(false));
                            dispatch(setPageWithMenuGroups(""));
                        }}
                        style={personalMenuBtn}
                    >
                        {/* {iconMap["Tracking"]} */}
                        <span style={{ marginLeft: "8px" }}>
                            {t(`menu.${personalAccount.name}`)}
                        </span>
                    </NavLink>
                )}

                {/* Отображаем остальные кнопки меню */}
                {otherMenus.map((allowedMenu) => {
                    return (
                        <React.Fragment key={allowedMenu.id}>
                            {allowedMenu.name === "Catalogs" ||
                            allowedMenu.name === "Contracts" ||
                            allowedMenu.name === "Administration"  ? (
                                <Accordion
                                    style={{ borderRadius: "0", padding: 0 }}
                                    sx={navBarAccordion}
                                    disableGutters
                                    expanded={expanded === allowedMenu.name}
                                    onChange={handleChange(allowedMenu?.name)}
                                >
                                    <NavLink
                                        to={allowedMenu.name.toLowerCase()}
                                        className={"navBarMenuTitle"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(
                                                setPageWithMenuGroups(
                                                    allowedMenu.name,
                                                ),
                                            );
                                        }}
                                    >
                                        {iconMap[allowedMenu.name]}
                                        <AccordionSummary
                                            style={{ minHeight: 0, margin: 0 }}
                                            sx={{
                                                width: "100%",
                                                padding:
                                                    "0.7333rem 0.100rem 0.8rem 0.8667rem",
                                            }}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography
                                                sx={navBarAccordionSummary}
                                            > 
                                                {t(
                                                    "menu." +
                                                        getPageName(
                                                            allowedMenu.name,
                                                        ),
                                                )}
                                            </Typography>
                                        </AccordionSummary>
                                    </NavLink>
                                    {isAllowedGroupsLoading ? (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                margin: "15px 0",
                                            }}
                                        >
                                            <CircularProgress
                                                size={25}
                                                sx={{ margin: "0 auto" }}
                                            />
                                        </Box>
                                    ) : allowedMenu.name === "Catalogs" ? (
                                        [...allowedGroups]
                                            .sort(
                                                (
                                                    a: PermissionGroup,
                                                    b: PermissionGroup,
                                                ) =>
                                                    t(
                                                        "menu." +
                                                            a.name.replace(
                                                                "api/",
                                                                "",
                                                            ),
                                                    ).localeCompare(
                                                        t(
                                                            "menu." +
                                                                b.name.replace(
                                                                    "api/",
                                                                    "",
                                                                ),
                                                        ),
                                                        currentLanguage ===
                                                            "en-US"
                                                            ? "en"
                                                            : "ru",
                                                        {
                                                            sensitivity:
                                                                "accent",
                                                        },
                                                    ),
                                            )
                                            .map((allowedGroup) => (
                                                <NavLink
                                                    key={allowedGroup.id}
                                                    to={
                                                        (pageWithMenuGroups &&
                                                            allowedGroup.name
                                                                .replace(
                                                                    "api",
                                                                    pageWithMenuGroups[0].toLowerCase() +
                                                                        pageWithMenuGroups.slice(
                                                                            1,
                                                                        ),
                                                                )
                                                                .toLowerCase()) ||
                                                        "/"
                                                    }
                                                    className={
                                                        "navBarAccordionDetails"
                                                    }
                                                >
                                                    <AccordionDetails
                                                        sx={{ padding: 0 }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                ...navBarAccordionSummary,
                                                                padding:
                                                                    "0.7333rem 0.8667rem 0.8rem",
                                                            }}
                                                        >
                                                            {t(
                                                                "menu." +
                                                                    getPageName(
                                                                        allowedGroup.name,
                                                                    ),
                                                            )}
                                                        </Typography>
                                                    </AccordionDetails>
                                                </NavLink>
                                            ))
                                    ) : (
                                        allowedGroups.map((allowedGroup) => {
                                            return (
                                                <NavLink
                                                    key={allowedGroup.id}
                                                    to={
                                                        (pageWithMenuGroups &&
                                                            allowedGroup.name
                                                                .replace(
                                                                    "api",
                                                                    pageWithMenuGroups[0].toLowerCase() +
                                                                        pageWithMenuGroups.slice(
                                                                            1,
                                                                        ),
                                                                )
                                                                .toLowerCase()) ||
                                                        "/"
                                                    }
                                                    className={
                                                        "navBarAccordionDetails"
                                                    }
                                                >
                                                    <AccordionDetails
                                                        sx={{ padding: 0 }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                ...navBarAccordionSummary,
                                                                padding:
                                                                    "0.7333rem 0.8667rem 0.8rem",
                                                            }}
                                                        >
                                                            {t(
                                                                "menu." +
                                                                    getPageName(
                                                                        allowedGroup.name,
                                                                    ),
                                                            )}
                                                        </Typography>
                                                    </AccordionDetails>
                                                </NavLink>
                                            );
                                        })
                                    )}
                                </Accordion>
                                
                            ) : (<NavLink
                                to={allowedMenu.name.toLowerCase()}
                                onClick={() => {
                                    dispatch(handleNavBarExpanded(false));
                                    dispatch(setPageWithMenuGroups(""));
                                }}
                                className="organizations"
                            >
                                <span>{iconMap[allowedMenu.name]}</span>
                                <p>{t(`menu.${allowedMenu.name}`)}</p>
                            </NavLink>
                                
                            )}
                        </React.Fragment>
                    );
                })}
            </nav>
        </>
    );
};

export default NavBar;
