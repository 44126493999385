import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FixedSizeList as List } from "react-window";

import { ErrorMessage } from "@hookform/error-message";
import { Box, TextField } from "@mui/material";

import ConfirmationModal from "../../../../features/ConfirmationModal";
import { RootState } from "../../../../store";
import { fetchVacationBalances } from "../../../../store/thunks/vacationSickDaysRemainThunk";
import Loader from "../../../../ui/Loader";

import styles from "./vacationSickDaysRemainingTable.module.scss";
interface EmployeeData {
  personnelNumber: number;
  employeeId: string;
  name?: string | null;
  balance: string | null;
  year: number | null;
}

interface FormValues {
  employees: EmployeeData[];
}

interface RemainingDaysTableProps {
  data: EmployeeData[];
  onSave: (data: EmployeeData[]) => void;
}

const RemainingDaysTable: FC<RemainingDaysTableProps> = ({
  data,
  onSave,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const { loading } = useAppSelector(
    (state: RootState) => state.vacationBalances,
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<FormValues>({
    defaultValues: { employees: [] },
  });

  const { replace } = useFieldArray({
    control,
    name: "employees",
  });

  const [originalData, setOriginalData] = useState<EmployeeData[]>(data);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | "default">("asc");
  const [sortedData, setSortedData] = useState<EmployeeData[]>(data);

  const sortByName = () => {
    let nextSortOrder: "asc" | "desc" | "default";

    if (sortOrder === "asc") {
      nextSortOrder = "desc";
    } else if (sortOrder === "desc") {
      nextSortOrder = "default";
    } else {
      nextSortOrder = "asc";
    }

    let sorted;
    if (nextSortOrder === "default") {
      sorted = [...originalData];
    } else {
      sorted = [...sortedData].sort((a, b) => {
        const nameA = a.name?.toLowerCase() || "";
        const nameB = b.name?.toLowerCase() || "";
        if (nameA < nameB) return nextSortOrder === "asc" ? -1 : 1;
        if (nameA > nameB) return nextSortOrder === "asc" ? 1 : -1;
        return 0;
      });
    }

    setSortOrder(nextSortOrder);
    setSortedData(sorted);
    replace(sorted);
  };

  useEffect(() => {
    dispatch(fetchVacationBalances(2025));
  }, [dispatch]);

  useEffect(() => {
    if (data?.length > 0) {
      const formattedEmployees: EmployeeData[] = data.map((employee) => ({
        personnelNumber: employee.personnelNumber,
        employeeId: employee.employeeId,
        name: employee.name,
        balance: employee.balance ?? null,
        year: employee.year ?? null,
      }));
      setOriginalData(formattedEmployees);
      setSortedData(formattedEmployees);
      replace(formattedEmployees);
    }
  }, [data, replace]);

  const onSubmit = (formData: FormValues) => {
    const changedBalances = formData.employees.filter((employee, index) => {
      const original = data[index];
      return original && employee.balance !== original.balance;
    });
    if (changedBalances.length > 0) {
      onSave(changedBalances);
    }
  };

  const closeModalConfirm = () => {
    if (isDirty) {
      navigate(-1);
      setConfirmationModalOpen(false);
      reset({}, { keepValues: true });
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    const focusNextOrPrevField = (direction: "next" | "prev") => {
      const nextIndex = direction === "next" ? index + 1 : index - 1;
      const targetField = document.querySelector<HTMLInputElement>(
        `input[name="employees.${nextIndex}.balance"]`,
      );
      if (targetField) {
        targetField.focus();
      }
    };
    if (e.key === "Enter" || e.key === "ArrowDown") {
      e.preventDefault();
    }
    if (e.key === "Enter" || e.key === "ArrowDown") {
      focusNextOrPrevField("next");
    }
    if (e.keyCode === 38) {
      e.preventDefault();
      const prevField = document.querySelector<HTMLInputElement>(
        `input[name="employees.${index - 1}.balance"]`,
      );
      if (prevField) {
        prevField.focus();
      }
    }
  };

  const Row = React.memo(
    ({
      index,
      style,
      data,
    }: {
      index: number;
      style: React.CSSProperties;
      data: EmployeeData[];
    }) => {
      const field = data[index];
      return (
        <div style={style} key={field.personnelNumber || index}>
          <div className={styles.field_container}>
            <div className={styles.field_personnelNumber}>
              {field.personnelNumber}
            </div>
            <div className={styles.field_name}>{field.name}</div>
            <div className={styles.field_balance}>
              <Controller
                name={`employees.${index}.balance`}
                control={control}
                rules={{
                  pattern: {
                    value: /^\d+$/,
                    message: t("validation.onlyIntegers"),
                  },
                  min: {
                    value: 0,
                    message: t("validation.positiveNumber"),
                  },
                  maxLength: {
                    value: 3,
                    message: t("validation.maxThreeDigits"),
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    sx={{
                      width: "80px",
                      padding: "5px 10px",
                      "& .MuiInputBase-root": {
                        height: "35px",
                      },
                      "& input": {
                        alignContent: "center",
                      },
                      "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                        {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    }}
                    {...field}
                    type="number"
                    size="small"
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      field.onChange(value === null ? "" : value);
                      // onChange();
                    }}
                    onKeyDown={(e) =>
                      handleKeyDown(
                        e as React.KeyboardEvent<HTMLInputElement>,
                        index,
                      )
                    }
                    error={!!fieldState.error}
                    className={
                      errors.employees?.[index]?.balance
                        ? styles.catalogsDrawerInputError
                        : styles.catalogsDrawerInput
                    }
                  />
                )}
              />
              <ErrorMessage
                name={`employees.${index}.balance`}
                errors={errors}
                render={({ message }) => (
                  <span className={styles.selectErrorMsg}>{message}</span>
                )}
              />
            </div>
          </div>
        </div>
      );
    },
  );

  return (
    <Box padding={2}>
      {loading ? <Loader /> :
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ width: "800px", height: "600px" }}>
          <div className={styles.table_header}>
            <span style={{ width: "150px" }}>{t("data.tabNo")}</span>
            <span className={styles.table_header_name} onClick={sortByName}>
              {t("data.FIO")}
              <span style={{ marginLeft: "5px" }}>
                {sortOrder === "asc" ? "▲" : "▼"}
              </span>
            </span>

            <span style={{ width: "100px" }}>
              {t("vacationsSickDays.VacationDaysRemain")}
            </span>
          </div>

          <List
            height={560}
            itemCount={sortedData.length}
            itemSize={35}
            width="800px"
          >
            {({ index, style }) => (
              <Row
                key={data[index].personnelNumber || index} // Используем data[index]
                index={index}
                style={style}
                data={sortedData}
              />
            )}
          </List>
        </Box>
      </form>}
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        setIsModalOpen={setConfirmationModalOpen}
        actionName={"Close"}
        instanceName=""
        message={"closeFormWithoutSaving"}
        handleAction={closeModalConfirm}
      />
    </Box>
  );
};

export default RemainingDaysTable;
