import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import axiosApi from "../../../../core/request";
import { useAppSelector } from "../../../../hooks";
import Loader from "../../../../ui/Loader";
import { Text } from "../../../../ui/Text/Text";
import { sendNotification } from "../../../../ui/Toast";

import { WeekendCard } from "./components/WeekendCard";

import styles from "./styles.module.scss";

interface CalendarData {
  allDay: boolean | null;
  description: string | null;
  endDate: string;
  id: number | null;
  startDate: string | null;
  title: string | null;
  type: number | null;
}

export const Holidays = () => {
  const { t } = useTranslation();

  const employeeId = useAppSelector((state) => state.auth.userId);
  const [calendarData, setCalendarData] = useState<CalendarData[]>([]);
  const [metaInfo, setMetaInfo] = useState({
    maxHolidaysPerYear: 0,
    isHolidaysModifiable: false,
    isVacationModifiable: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [addedHolidays, setAddedHolidays] = useState<
    {
      newId: string | null;
      date: Dayjs | null;
      note: string;
    }[]
  >([]);



  const [holidaysToDelete, setHolidaysToDelete] = useState<number[]>([]);

  const handleSave = async () => {
    try {
      setIsLoading(true);

      const daysOffDto = addedHolidays.map((holiday) => {
        const localStartDate = dayjs(holiday.date).startOf("day");
        const offset = localStartDate.utcOffset();
        const offsetInHours = dayjs().utcOffset() / 60;

        const adjustedDate = localStartDate
          .utc()
          .add(offset + offsetInHours, "minute")
          .format();

        return {
          startDate: adjustedDate,
          finishDate: adjustedDate,
          sign: 3,
          remark: holiday.note || null,
        };
      });

      if (daysOffDto.length > 0) {
        const payload = {
          employeeId,
          daysOffDto,
        };

        await axiosApi.post("/personal-cabinet/calendar/daysoff", payload);
      }

      if (holidaysToDelete.length > 0) {
        await axiosApi.delete("/personal-cabinet/calendar/daysoff", {
          data: { ids: holidaysToDelete },
        });
      }

      sendNotification(t("notifications.successfullySaved"), "success");

      setAddedHolidays([]);
      setHolidaysToDelete([]);
      setIsEdit(false);
      fetchData();
    } catch (error) {
      sendNotification(t("statuses.Error"), "error");
      console.error("Error saving holidays:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddNewHoliday = (holiday: {
    date: Dayjs | null;
    note: string;
    newId: string | null;
  }) => {
    setAddedHolidays((prev) => [...prev, holiday]);
  };

  const handleMarkForDelete = (id: number) => {
    setHolidaysToDelete((prev) => [...prev, id]);
    setCalendarData((prev) => prev.filter((item) => item.id !== id));
  };

  const handleRemoveAddedHoliday = (newId: string | null) => {
    setAddedHolidays((prev) =>
      prev.filter((holiday) => holiday.newId !== newId),
    );
  };


  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [calendarRes, metaRes] = await Promise.all([
        axiosApi.get("/personal-cabinet/calendar", { params: { employeeId } }),
        axiosApi.get(`/personal-cabinet/calendar/meta?employeeId=${employeeId}`),
      ]);

      setCalendarData(calendarRes.data.filter((item: any) => item.type === 3));
      setMetaInfo(metaRes.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [employeeId]);

  return (
    <div>
      <div>
        {!isLoading && (
          <div className={styles.meta_info}>
            <div>
              <Text size={20} variant="REGULAR">
                {t("account.filled-in")}
                <span className={styles.value_color}>
                      &nbsp; {calendarData.length} &nbsp;
                    </span>
                {t("account.of")}
                <span className={styles.value_color}>
                      &nbsp; {metaInfo.maxHolidaysPerYear}
                    </span>
              </Text>
            </div>
            {metaInfo.isHolidaysModifiable && (
              <Button
                disabled={isLoading}
                variant="contained"
                onClick={isEdit ? handleSave : () => setIsEdit(!isEdit)}
              >
                {isEdit ? t("buttons.Save") : t("buttons.Edit")}
              </Button>
            )}
            {isEdit && (
              <Button
                disabled={isLoading}
                variant="contained"
                onClick={() => {
                  setIsEdit(!isEdit);
                  setAddedHolidays([]);
                  setHolidaysToDelete([]);
                  fetchData();
                }}
              >
                {t("buttons.Close")}
              </Button>
            )}
          </div>
        )}
      </div>
      <div className={styles.cards_container}>
        {isLoading ? (
          <div
            style={{
              width: "100%",
            }}
          >
            <Loader/>
          </div>
        ) : isEdit ? (
          <>
            {metaInfo.isHolidaysModifiable &&
              [...calendarData, ...addedHolidays].length <
              metaInfo.maxHolidaysPerYear && (
                <WeekendCard
                  selectedDates={[
                    ...calendarData.map(
                      (item) =>
                        ({
                          date: item.startDate,
                        }).date,
                    ),
                    ...addedHolidays.map((item) => item.date),
                  ]}
                  isNew
                  onAdd={handleAddNewHoliday}
                />
              )}

            {[...calendarData, ...addedHolidays].map((item: any) => (
              <WeekendCard
                key={item.id || item.newId}
                id={item.id || null}
                date={item.id ? item.startDate : item.date}
                note={item.id ? item.description : item.note}
                onDelete={
                  item.id
                    ? () => handleMarkForDelete(item.id!)
                    : () => handleRemoveAddedHoliday(item.newId)
                }
              />
            ))}
          </>
        ) : (
          <div>
            <div className={styles.event_wrapper}>
              {calendarData.map((item: CalendarData, idx) => (
                <div key={item.id} className={styles.event}>
                      <span>
                        <b>{idx + 1}</b>
                      </span>
                  <span>{moment(item.startDate).format("DD.MM.YYYY")}</span>
                  <span>{item.description}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
