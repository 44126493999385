import { useState } from "react";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { Text } from "ui/Text/Text";
import { sendNotification } from "ui/Toast";

import AdapterDayjs from "@date-io/date-fns";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { cancelBtn as resetFiltersBtn } from "../../../../../styles/MUIStyles";

import styles from "./styles.module.scss";

const ALLOWED_YEAR = 2025;

export const WeekendCard = ({
                              id,
                              date,
                              note,
                              isNew,
                              onAdd,
                              onDelete,
                              selectedDates,
                            }: {
  id?: string | null;
  date?: Dayjs | null;
  note?: string;
  isNew?: boolean;
  onAdd?: (holiday: { date: Dayjs | null; note: string, newId: string | null }) => void;
  onDelete?: (id: string) => void;
  selectedDates?: any[];
}) => {
  const { t } = useTranslation();

  const [localNote, setLocalNote] = useState(note || "");
  const [localDate, setLocalDate] = useState<Dayjs | null>(date || null);
  const [isDateValid, setIsDateValid] = useState(false);

  const handleAdd = () => {

    if (selectedDates?.some(el => dayjs(el).format("DD/MM/YYYY") === dayjs(localDate).format("DD/MM/YYYY"))) {
      console.log('date already selected');
      sendNotification(t("data.already-exists"), "warn");
      return;
    }

    if (localDate) {
      onAdd?.({
        date: localDate,
        note: localNote,
        newId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      });

      setLocalDate(null);
      setLocalNote('');
    }
  };

  // TODO вынести в redux
  const handleDelete = async () => {
    try {
      // @ts-ignore
      onDelete?.(id);
    } catch (error) {
      sendNotification(t("statuses.Error"), "error");
      console.error("Error deleting day off", error);
    }
  };

  const handleChange = (newValue: Dayjs | null) => {
    setLocalDate(newValue);
    const date = dayjs(newValue);

    if (date && date.isValid() && date.year() === ALLOWED_YEAR) {
      setIsDateValid(true);
    } else {
      setIsDateValid(false);
    }

  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={styles.container_wrapper}>
        <div>
          <div className={styles.card_wrapper}>
            <div className={styles.card_container}>
              <div className={styles.card_row}>
                <Text variant="REGULAR">{t("data.date")}</Text>
                <DesktopDatePicker
                  shouldDisableDate={(date) => {
                    // @ts-ignore
                    const year = date.getFullYear();
                    return year !== ALLOWED_YEAR;
                  }}
                  // @ts-ignore
                  defaultCalendarMonth={new Date(ALLOWED_YEAR, 0)}
                  disabled={!isNew}
                  inputFormat="dd/MM/yyyy"
                  value={localDate}
                  onChange={handleChange}
                  renderInput={(params) => (
                    <TextField {...params} inputProps={{ ...params.inputProps, placeholder: "дд.мм.гггг" }}/>
                  )}
                />
              </div>
              <div className={styles.card_row}>
                <Text variant="REGULAR">{t("data.note")}</Text>
                <textarea
                  disabled={!isNew}
                  placeholder={t("data.note") || ""}
                  className={styles.note}
                  name="note"
                  value={localNote}
                  onChange={(e) => setLocalNote(e.target.value)}
                />
              </div>
            </div>
            <CloseIcon
              className={classNames(styles.closeIcon, {
                [styles.hide_icon]: isNew,
              })}
              color="error"
              fontSize="large"
              onClick={handleDelete}
            />
          </div>
          {isNew && (
            <div className={styles.button_wrapper}>
              <Button
                variant="contained"
                disabled={!localDate || !isDateValid}
                className={styles.button_add}
                onClick={handleAdd}
              >
                {t("buttons.Add")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </LocalizationProvider>
  );
};
