import React, { FC, useEffect, useState } from "react";
import { ICellRendererParams, RowNode } from "ag-grid-community";
import classNames from "classnames";
import dayjs from "dayjs";
import AgGrid from "features/AgGrid/AgGrid";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Box, TableRow } from "@mui/material";

import editIcon from "../../assets/icons/hoverEditIcon.svg";
import viewIcon from "../../assets/icons/view.svg";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setTracking } from "../../store";
import { getTrackingByEmployeeId } from "../../store/thunks/trackingThunk";
import {
  materialReactTableHeader,
  projectsListTableCell,
} from "../../styles/MUIStyles";
import BootstrapTooltip from "../../ui/Tooltip";

import styles from "./Tracking.module.scss";

interface Props {
  year: number;
  month: number;
  // activeTracking: IEmployeeTracking | null;
}

const TrackingTable: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { year, month } = props;
  const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);
  const [totalFte, setTotalFte] = useState<number | null>(null);
  const [totalHours, setTotalHours] = useState<number | null>(null);
  const [highlightedRows, setHighlightedRows] = useState<string>();
  const hasAccess = allowedMenus.find((item) => item.name === "Projects");
  const hasAccessClient = allowedMenus.find(
    (item) => item.name === "Organizations",
  );
  const activeTracking = useAppSelector(
    (state) => state.tracking.activeTracking,
  );
  const { userId } = useAppSelector((state) => state.auth);
  const employeeTracking = useAppSelector(
    (state) => state.tracking.employeeTracking,
  );




  useEffect(() => {
    dispatch(
      getTrackingByEmployeeId({
        employeeId: userId,
        // employeeId: '5e58e21e-8a58-40bb-d84d-08db39b21bcd',
        year: year,
        month: month,
      }),
    );
  }, [year, month, userId, dispatch]);

  const getFteColor = (fte: number) => {
    if (fte > 0.5 && fte < 1.3) return "#01AE52";
    else if ((fte >= 0.1 && fte <= 0.5) || (fte >= 1.3 && fte <= 1.8))
      return "#F5A96A";
    else return "#FE0A0B";
  };

  const getHoursColor = (hours: number) => {
    if (hours > 80 && hours < 208) return "#01AE52";
    else if ((hours >= 16 && hours <= 80) || (hours >= 208 && hours <= 288))
      return "#F5A96A";
    else return "#FE0A0B";
  };

  useEffect(() => {
    const fte = employeeTracking.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.fte;
    }, 0);

    setTotalFte(fte);
    const hours = employeeTracking.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.totalTime;
    }, 0);
    setTotalHours(hours);
  }, [employeeTracking]);

  const HeadComponent = (props: any) => {
    switch (props?.column?.colId) {
      case "customActions":
        return null;
      default:
        return <Box>{t(`tracking.${props.column.colId}`)}</Box>;
    }
  };

  const HeadGroupFteComponent = () => {
    return (
      <TableRow
        sx={{
          border: "none",
          borderBottom: "none",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Box sx={{ border: "none" }}>
          <b
            style={{
              color: getFteColor(totalFte || 0),
              fontSize: 20,
            }}
          >
            {totalFte}
          </b>
        </Box>
      </TableRow>
    );
  };
  const HeadGroupAllComponent = () => {
    return (
      <TableRow
        sx={{
          border: "none",
          borderBottom: "none",
          width: "100%",
          textAlign: "end",
          fontSize: 20,
        }}
      >
        {t("tracking.total")}:
      </TableRow>
    );
  };
  const HeadGroupHoursComponent = () => {
    return (
      <TableRow
        sx={{
          border: "none",
          borderBottom: "none",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Box sx={{ border: "none" }}>
          <b
            style={{
              color: getHoursColor(totalHours || 0),
              fontSize: 20,
            }}
          >
            {totalHours}
          </b>
        </Box>
      </TableRow>
    );
  };

  const onRowMouseEnter = (event: { node: RowNode }) => {
    const rowNode = event.node;
    setHighlightedRows(rowNode.id!);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onRowMouseLeave = (event: { node: RowNode }) => {
    setHighlightedRows(null!);
  };

  const gridOptions = {
    onCellMouseOver: onRowMouseEnter,
    onCellMouseOut: onRowMouseLeave,
    getRowNodeId: (data: any) => data.id,
    frameworkComponents: {
      headerComponent: HeadComponent,
    },
    defaultColDef: {
      resizable: false,
    },
    suppressMovableColumns: true,
    rowClass: "rowClassTracking",
    headerClass: "headClassTracking",
    flex: 1,
    boxShadow: false,
  };

  const RowComponent = (value: ICellRendererParams) => {

    switch (value.colDef?.field) {
      case "startDate":
        return (
          <Box>
            {value.data.startDate
              ? dayjs(value.data.startDate).format("DD.MM.YYYY")
              : null}
          </Box>
        );
      case "projectCode":
        return allowedMenus.some((menu) => menu.name === "Projects") ? (
          <Box>
            <div
              onClick={() =>
                hasAccess && navigate(`/projects/view/${value.data.projectId}`)
              }
              className={classNames("hyperLink", {
                [styles.projectLink]: hasAccess,
                [styles.projectText]: !hasAccess,
              })}
            >
              <b>{value.data.projectCode}</b>
            </div>
          </Box>
        ) : (
          <b>{value.data.projectCode}</b>
        );
      case "shortName":
        return allowedMenus.some((menu) => menu.name === "Projects") ? (
          <Box>
            <div
              onClick={() =>
                hasAccess && navigate(`/projects/view/${value.data.projectId}`)
              }
              className={classNames("hyperLink", {
                [styles.projectLink]: hasAccess,
                [styles.projectText]: !hasAccess,
              })}
            >
              <b>{value.data.shortName}</b>
            </div>
          </Box>
        ) : (
          <b>{value.data.shortName}</b>
        );
      case "customActions":
        return (
          <Box sx={projectsListTableCell}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                width: "40%",
                cursor: "pointer",
              }}
            >
              <BootstrapTooltip
                sx={{ margin: "auto 0" }}
                title={t("buttons.View")}
                onClick={() => {
                  navigate(
                    value.data.timekeepingId
                      ? `management/view/${value.data.timekeepingId}`
                      : `management/view`,
                  );
                  dispatch(setTracking(value.data));
                }}
                placement="top"
              >
                <img src={viewIcon} alt="view icon" />
              </BootstrapTooltip>
              <BootstrapTooltip
                onClick={() =>
                  navigate(
                    value.data.timekeepingId
                      ? `management/edit/${value.data.timekeepingId}`
                      : `management/edit`,
                  )
                }
                title={t("buttons.Edit")}
                placement="top"
              >
                <img src={editIcon} alt="edit icon" />
              </BootstrapTooltip>
            </Box>
          </Box>
        );

      default:
        return <Box sx={materialReactTableHeader}>{value.value}</Box>;
    }
  };

  const generateColumnDefs = () => {
    // const savedColumnState = getFromLocalStorage("agGridTrackingByEmployeeTable");

    const updatedColumnDefs: any[] = [
      {
        headerName: "",
        resizable: false,
        children: [
          {
            field: "client",
            headerComponent: HeadComponent,
            cellRenderer: RowComponent,
            resizable: false,
          },
          {
            field: "projectCode",
            headerComponent: HeadComponent,
            cellRenderer: RowComponent,
            width: 150,
            resizable: false,
          },
          {
            field: "shortName",
            headerComponent: HeadComponent,
            cellRenderer: RowComponent,
            resizable: false,
          },
        ],
      },
      {
        headerGroupComponent: HeadGroupAllComponent,
        resizable: false,
        children: [
          {
            field: "startDate",
            headerComponent: HeadComponent,
            cellRenderer: RowComponent,
            resizable: false,
            width: 200,
          },
        ],
      },
      {
        headerGroupComponent: HeadGroupFteComponent,
        resizable: false,
        children: [
          {
            field: "fte",
            headerComponent: HeadComponent,
            cellRenderer: RowComponent,
            resizable: false,
            width: "60px",
          },
        ],
      },
      {
        headerGroupComponent: HeadGroupHoursComponent,
        resizable: false,
        width: 300,
        children: [
          {
            field: "totalTime",
            headerComponent: HeadComponent,
            cellRenderer: RowComponent,
            resizable: false,
            width: "80px",
          },
        ],
      },
      {
        resizable: false,
        flex: 1,
        children: [
          {
            field: "customActions",
            headerComponent: HeadComponent,
            cellRenderer: RowComponent,
            resizable: false,
            flex: 1,
          },
        ],
      },
    ];

    //TODO: спросить почему закоммитил
    // if (Array.isArray(savedColumnState)) {
    //     updatedColumnDefs = savedColumnState.map((columnState: any) => {
    //         return {
    //             field: columnState.colId,
    //             width: columnState.width,
    //             pinned: columnState.pinned,
    //             headerComponent: HeadComponent,
    //             cellRenderer: RowComponent,
    //             resizable: true,
    //         };
    //     });
    // }

    return updatedColumnDefs;
  };

  const generateTest = generateColumnDefs();

  const saveColumnState = (columnState: any) => {
    localStorage.setItem(
      "agGridTrackingByEmployeeTable",
      JSON.stringify(columnState),
    );
  };


  return (
    <div style={{ marginRight: "1px" }}>
      <AgGrid
        rowData={employeeTracking || []}
        columnDefs={generateTest}
        saveColumnState={saveColumnState}
        activeRow={activeTracking}
        setActiveRow={setTracking}
        gridOptions={gridOptions}
        height="65vh"
        page="trackingEmployee"
      />
    </div>
  );
};

export default TrackingTable;
