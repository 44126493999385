import {
  deleteDaysOffByEmployee,
  getDaysOffAll,
  getDaysOffByEmployee,
} from "store/thunks/vacationsSickDaysThunk";

import { createSlice } from "@reduxjs/toolkit";

import { IDayOffAll } from "./../../models/vacationsSickDays";

interface IVacationsSickDays {
  dayOffAll: IDayOffAll[];
  isGettingDaysOffAllLoding: boolean;
  vacationsSickDaysPageCount: number;
  daysOffByEmployee: any; //fix
  isGettingDaysOffByEmployeeLoding: boolean;
  isDeletingDaysOffByEmployeeLoading: boolean;
  activeVacationSickDay: IDayOffAll | null;
}

const initialState: IVacationsSickDays = {
  dayOffAll: [],
  isGettingDaysOffAllLoding: false,
  vacationsSickDaysPageCount: 1,
  daysOffByEmployee: {},
  isGettingDaysOffByEmployeeLoding: false,
  isDeletingDaysOffByEmployeeLoading: false,
  activeVacationSickDay: null,
};

const vacationsSickDaysSlice = createSlice({
  name: "vacationsSickDays",
  initialState,
  reducers: {
    setActiveVacationSickDay: (state, action) => {
      state.activeVacationSickDay = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDaysOffAll.pending, (state) => {
        state.isGettingDaysOffAllLoding = true;
      })
      .addCase(getDaysOffAll.fulfilled, (state, action) => {
        state.dayOffAll = action.payload.data;
        state.vacationsSickDaysPageCount = action.payload.metadata.pageCount;
        state.isGettingDaysOffAllLoding = false;
      })
      .addCase(getDaysOffAll.rejected, (state) => {
        state.isGettingDaysOffAllLoding = false;
      })
      .addCase(getDaysOffByEmployee.pending, (state) => {
        state.isGettingDaysOffByEmployeeLoding = true;
      })
      .addCase(getDaysOffByEmployee.fulfilled, (state, action) => {
        state.isGettingDaysOffByEmployeeLoding = false;
        state.daysOffByEmployee = action.payload;
      })
      .addCase(getDaysOffByEmployee.rejected, (state) => {
        state.isGettingDaysOffByEmployeeLoding = false;
      })

      .addCase(deleteDaysOffByEmployee.pending, (state) => {
        state.isDeletingDaysOffByEmployeeLoading = true;
      })
      .addCase(deleteDaysOffByEmployee.fulfilled, (state) => {
        state.isDeletingDaysOffByEmployeeLoading = false;
      })
      .addCase(deleteDaysOffByEmployee.rejected, (state) => {
        state.isDeletingDaysOffByEmployeeLoading = false;
      });
  },
});
export const { setActiveVacationSickDay } = vacationsSickDaysSlice.actions;

export default vacationsSickDaysSlice.reducer;
