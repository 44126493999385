import React, { FC } from 'react';
import { IAllSkillLevels, IAllSkills, IEmployeeSkill } from 'models/employees';
import { useTranslation } from 'react-i18next';

import styles from '../EmployeeView/Employees.module.scss';

interface Props {
    id: string;
    employeeFirstName: string;
    employeeLastName: string;
    employeeSkills: IEmployeeSkill[];
    allSkills: IAllSkills[];
    allSkillLevels: IAllSkillLevels[];
    fte: number;
    allowedPermissions: any[];
    showCloseButton?: boolean;
}

const EmployeeSkills: FC<Props> = (props): JSX.Element => {
    const {
        employeeSkills,
        allSkills,
        allSkillLevels,
        fte,
    } = props;
    const { t } = useTranslation();
    return (
        <div style={{ padding: '10px 0px' }}>
            <div className={styles.employeeSkills}>
                <h3>FTE: {fte}</h3>
                <h3>{t('employees.allSkills')}</h3>
                <table className={styles.skillsList}>
                    {employeeSkills.map((employeeSkill) => (
                        <tr key={employeeSkill.id}>
                            <td>
                                {allSkills.find(
                                    (skill) =>
                                        skill.id === employeeSkill.skillId
                                )?.name || null}
                            </td>
                            <td>
                                {allSkillLevels.find(
                                    (skillLevel) =>
                                        skillLevel.id ===
                                        employeeSkill.skillLevelId
                                )?.name || null}
                            </td>
                            <td>
                                {employeeSkill.isBasicSkill
                                    ? t('data.mainSkill')
                                    : null}
                            </td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    );
};

export default EmployeeSkills;
