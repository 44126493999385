import { FC } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import styles from "../VacationsSickDaysViewEmployee.module.scss";

const DaysOffViewEmployeeCard: FC<any> = ({ title, vacationBalance, daysOff }) => {
    const { t } = useTranslation();

    const countDay = daysOff?.reduce(
        (countDay: any, item: any) => countDay + item.numberOfDaysOff,
        0,
    );

    return (
        <div className={styles.card}>
            <div className={styles.boxCard_top}>
                <h4>{title}</h4>
                <div>{countDay}</div>
            </div>
            {title === t("vacationsSickDays.Vacations") && (
                <div className={styles.boxCard_VacationDaysLeft}>
                    <h4>{t("vacationsSickDays.VacationDaysLeftForYear")}</h4>
                    <span>{vacationBalance}</span>
                </div>
            )}
            <ol className={styles.list}>
                {daysOff?.map((dayOff: any) => {
                    return (
                        <li key={dayOff.id}>
                            <p>
                                {dayjs(dayOff.startDate).format("DD.MM.YYYY")}
                                {title === t("vacationsSickDays.DaysOff")
                                    ? ""
                                    : " - " +
                                      dayjs(dayOff.finishDate).format(
                                          "DD.MM.YYYY",
                                      )}
                                <b>( {dayOff.numberOfDaysOff} )</b>
                            </p>
                            <p className={styles.list_remark}>
                                {dayOff.remark}
                            </p>
                        </li>
                    );
                })}
            </ol>
        </div>
    );
};

export default DaysOffViewEmployeeCard;
