// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_buttonGroup__JhXa4 {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n.styles_listDays__j8i00 {\n  font-size: 20px;\n  margin: 10px 0;\n  font-weight: 600;\n}", "",{"version":3,"sources":["webpack://./src/features/VacationConflictModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAGA;EACI,eAAA;EACA,cAAA;EACA,gBAAA;AAAJ","sourcesContent":[".buttonGroup {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n\n}\n\n.listDays {\n    font-size: 20px;\n    margin: 10px 0;\n    font-weight: 600;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonGroup": "styles_buttonGroup__JhXa4",
	"listDays": "styles_listDays__j8i00"
};
export default ___CSS_LOADER_EXPORT___;
