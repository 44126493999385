import { useState } from "react";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useTranslation } from "react-i18next";
import { Text } from "ui/Text/Text";
import { sendNotification } from "ui/Toast";

import AdapterDayjs from "@date-io/date-fns";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { DesktopDatePicker, PickersDay } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import VacationConflictModal from "../../../../../features/VacationConflictModal";
dayjs.extend(isBetween);

import styles from "./styles.module.scss";

const ALLOWED_YEAR = 2025;

export const VacationCard = ({
  id,
  date,
  note,
  isNew,
  onAdd,
  onDelete,
  // selectedDates,
  dateEnd,
  allDates,
  filledDates,
  maxVacationDaysPerYear,
  initialListOfDates,
}: {
  id?: string | null;
  date?: Dayjs | null;
  note?: string;
  isNew?: boolean;
  allDates?: any[];
  filledDates: number;
  onAdd?: (holiday: {
    date: Dayjs | null;
    dateEnd: Dayjs | null;
    note: string;
    id: string | null;
    pureDays?: number;
  }) => void;
  onDelete?: (id: string) => void;
  // selectedDates?: any[];
  dateEnd?: Dayjs | null;
  maxVacationDaysPerYear: number;
  initialListOfDates: any[];
}) => {
  const { t } = useTranslation();
  const [localNote, setLocalNote] = useState(note || "");
  const [localDate, setLocalDate] = useState<Dayjs | null>(date || null);
  const [localDateEnd, setLocalDateEnd] = useState<Dayjs | null>(
    dateEnd || null,
  );
  const [isDateValid, setIsDateValid] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [conflictingDays, setConflictingDays] = useState<string[]>([]);
  const checkIntersection = (
    start1: Dayjs,
    end1: Dayjs,
    start2: Dayjs,
    end2: Dayjs
  ): boolean => {
    return (
      dayjs(start1).isSame(start2, 'day') || // Совпадает начальная дата
      dayjs(end1).isSame(end2, 'day') || // Совпадает конечная дата
      (dayjs(start1).isBefore(end2, 'day') && dayjs(end1).isAfter(start2, 'day')) // Пересечение диапазонов
    );
  };

  const handleAdd = () => {
    const daysSelectedCount = (dayjs(localDateEnd)?.diff(dayjs(localDate), "day") + 1);
    const difference = maxVacationDaysPerYear - filledDates;

    if (daysSelectedCount < 7 && difference >= 7) {
      sendNotification(t('data.set7orMoreDays'), 'warn');
      return;
    }

    if (daysSelectedCount < 7 && daysSelectedCount !== difference) {
      sendNotification(t(`data.selectPeriodEqualTo`, { daysSelectedCount: difference }), "warn");
      return;
    }

    if (dayjs(localDateEnd)?.diff(dayjs(localDate)) < 0) {
      sendNotification(t('data.endDateMustBeAfterStart'), "warn");
      return;
    }

    if (dayjs(localDateEnd)?.diff(dayjs(localDate), "day") + 1 + filledDates > maxVacationDaysPerYear) {
      sendNotification(t('data.vacationRestrictions') + ` ${maxVacationDaysPerYear}`, "warn");
      return;
    }

    // проверка на то, что дата начала и дата окончания не пересекаются с уже выбранными датами
    const isDateOverlap = allDates?.some((el) => {
      const existingStartDate = dayjs(typeof el.id === "string" ? el.date : el.startDate);
      const existingEndDate = dayjs(typeof el.id === "string" ? el.dateEnd : el.endDate);

      // проверка не пересекаются ли даты с диапазоном
      return (
        (localDate &&
          localDateEnd &&
          dayjs(localDate).isBetween(
            existingStartDate,
            existingEndDate,
            null,
            "[)",
          ) &&
          dayjs(localDateEnd).isBetween(
            existingStartDate,
            existingEndDate,
            null,
            "(]",
          )) ||
        // Проверка если одна из дат (начальная или конечная) совпадает с уже существующей
        dayjs(localDate).isBetween(
          existingStartDate,
          existingEndDate,
          null,
          "[)",
        ) ||
        dayjs(localDateEnd).isBetween(
          existingStartDate,
          existingEndDate,
          null,
          "(]",
        ) ||
        dayjs(localDate).isSame(existingStartDate, "day") ||
        dayjs(localDateEnd).isSame(existingEndDate, "day")
      );
    });

    const isDateExactMatch = allDates?.some(
      (el) =>
        dayjs(typeof el.id === "string" ? el.date : el.startDate).isSame(localDate, "day") &&
        dayjs(typeof el.id === "string" ? el.dateEnd : el.endDate).isSame(localDateEnd, "day"),
    );

    if (isDateOverlap || isDateExactMatch) {
      sendNotification(t("data.already-exists"), "warn");
      return;
    }

    const intersectingDays = initialListOfDates.filter(item => item.type === 3).filter((dateRange) =>
      checkIntersection(
        localDate!,
        localDateEnd!,
        dayjs(dateRange.startDate),
        dayjs(dateRange.endDate)
      )
    );

    if (intersectingDays.length > 0) {
      setConflictingDays(
        intersectingDays.map((date) => dayjs(date.startDate).format('DD.MM.YYYY'))
      );
      setIsModalOpen(true);
      return;
    }

    if (localDate && localDateEnd && isDateValid) {
      onAdd?.({
        date: localDate,
        dateEnd: localDateEnd,
        note: localNote,
        id:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        pureDays: dayjs(localDateEnd).diff(localDate, 'day') + 1
      });

      setLocalDate(null);
      setLocalDateEnd(null);
      setLocalNote("");
    }
  };

  const handleDelete = async () => {
    try {
      // @ts-ignore
      onDelete?.(id);
    } catch (error) {
      sendNotification(t("statuses.Error"), "error");
      console.error("Error deleting day off", error);
    }
  };

  const handleChange = (newValue: Dayjs | null) => {
    setLocalDate(newValue);
    const date = dayjs(newValue);

    if (date && date.isValid() && date.year() === ALLOWED_YEAR) {
      setIsDateValid(true);
    } else {
      setIsDateValid(false);
    }
  };

  const handleChangeEndDate = (newValue: Dayjs | null) => {
    setLocalDateEnd(newValue);
    const date = dayjs(newValue);

    if (
      date &&
      date.isValid() &&
      date.year() === ALLOWED_YEAR
    ) {
      setIsDateValid(true);
    } else {
      setIsDateValid(false);
    }
  };

  const handleExtendVacation = () => {
    if (localDateEnd && localDate) {
      const adjustedDate = initialListOfDates.filter(item => item.type === 3).filter((dateRange) =>
        checkIntersection(
          localDate,
          localDateEnd,
          dayjs(dateRange.startDate),
          dayjs(dateRange.endDate)
        ));


      const newEndDate = dayjs(localDateEnd).add(adjustedDate.length, 'day');
      const intersectingDays = initialListOfDates.filter(item => item.type === 3).filter((dateRange) =>
        checkIntersection(
          localDate,
          newEndDate,
          dayjs(dateRange.startDate),
          dayjs(dateRange.endDate)
        )
      );

      if (intersectingDays.length > 0) {
        setConflictingDays(
          intersectingDays.map((date) => dayjs(date.startDate).format('DD.MM.YYYY'))
        );
      }

      onAdd?.({
        date: localDate,
        dateEnd: newEndDate,
        note: localNote,
        id:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15), // Используем текущий ID
        pureDays: dayjs(newEndDate).diff(localDate, 'day') + 1 - intersectingDays.length
      });

      setLocalDate(null);
      setLocalDateEnd(null);
      setIsModalOpen(false);
    }
  };

  const handleReduceDays = () => {

    if (localDateEnd && localDate) {
      const currentLocalDate = dayjs(localDate);
      const adjustedDate = initialListOfDates.filter(item => item.type === 3).filter((dateRange) =>
        checkIntersection(
          localDate,
          localDateEnd,
          dayjs(dateRange.startDate),
          dayjs(dateRange.endDate)
        ));

      const daysDifference = adjustedDate.length;

      const pureDays = dayjs(localDateEnd).diff(localDate, 'day') + 1 - daysDifference;


      onAdd?.({
        date: currentLocalDate,
        dateEnd: localDateEnd,
        note: localNote,
         id:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        pureDays: pureDays
      });

      setLocalDate(null);
      setLocalDateEnd(null);
      setIsModalOpen(false);
    }
  };

  const isHighlighted = (date: Date) => {
    const filteredDates = initialListOfDates?.filter(item => item.type === 3);

    return filteredDates?.some((highlightedDate) => {
      const startDate = dayjs(highlightedDate.startDate);

      return (
        date.getDate() === startDate.date() &&
        date.getMonth() === startDate.month() &&
        date.getFullYear() === startDate.year()
      );
    });
  };


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={styles.container_wrapper}>
        <div>
          <div className={styles.card_wrapper}>
            <div className={styles.card_container}>
              <div className={styles.card_row}>
                <Text className={styles.card_row_text} variant="REGULAR">{t("data.startDate")}</Text>
                <DesktopDatePicker
                  className={styles.date_picker}
                  shouldDisableDate={(date) => {
                    // @ts-ignore
                    const year = date.getFullYear();
                    return year !== ALLOWED_YEAR;
                  }}
                  // @ts-ignore
                  defaultCalendarMonth={new Date(ALLOWED_YEAR, 0)}
                  disabled={!isNew}
                  inputFormat="dd/MM/yyyy"
                  value={localDate}
                  onChange={handleChange}
                  renderDay={(day, _value, DayComponentProps) => {
                    // @ts-ignore
                    const isSelected = isHighlighted(day);

                    return (
                      <PickersDay
                        {...DayComponentProps}
                        sx={{
                          ...(isSelected && {
                            backgroundColor: "#FFD700",
                            color: "#000",
                            "&:hover": {
                              backgroundColor: "#FFC700",
                            },
                          }),
                        }}
                      />
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "дд.мм.гггг",
                      }}
                    />
                  )}
                />
              </div>
              <div className={styles.card_row}>
                <Text className={styles.card_row_text} variant="REGULAR">{t("data.endDate")}</Text>
                <DesktopDatePicker
                  className={styles.date_picker}
                  shouldDisableDate={(date) => {
                    // @ts-ignore
                    const year = date.getFullYear();
                    return year !== ALLOWED_YEAR;
                  }}
                  // @ts-ignore
                  defaultCalendarMonth={new Date(ALLOWED_YEAR, 0)}
                  disabled={!isNew}
                  inputFormat="dd/MM/yyyy"
                  value={localDateEnd}
                  onChange={handleChangeEndDate}
                  renderDay={(day, _value, DayComponentProps) => {
                    // @ts-ignore
                    const isSelected = isHighlighted(day);

                    return (
                      <PickersDay
                        {...DayComponentProps}
                        sx={{
                          ...(isSelected && {
                            backgroundColor: "#FFD700",
                            color: "#000",
                            "&:hover": {
                              backgroundColor: "#FFC700",
                            },
                          }),
                        }}
                      />
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "дд.мм.гггг",
                      }}
                    />
                  )}
                />
              </div>
              <div className={styles.card_row}>
                <Text className={styles.card_row_text} variant="REGULAR">{t("data.note")}</Text>
                <textarea
                  disabled={!isNew}
                  placeholder={isNew ? (t("data.note") || '') : t("data.noteIsMissing") || ''}
                  className={styles.note}
                  name="note"
                  value={localNote}
                  onChange={(e) => setLocalNote(e.target.value)}
                />
              </div>
            </div>
            <CloseIcon
              className={classNames(styles.closeIcon, {
                [styles.hide_icon]: isNew,
              })}
              color="error"
              fontSize="large"
              onClick={handleDelete}
            />
          </div>
          {isNew && (
            <div className={styles.button_wrapper}>
              <Button
                variant="contained"
                disabled={!localDate || !localDateEnd || !isDateValid}
                className={styles.button_add}
                onClick={handleAdd}
              >
                {t("buttons.Add")}
              </Button>
            </div>
          )}
        </div>
        <VacationConflictModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          onExtendVacation={handleExtendVacation}
          onReduceDays={handleReduceDays}
          conflictingDays={conflictingDays}
      />
      </div>
    </LocalizationProvider>
  );
};
