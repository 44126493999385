import React, { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { cancelBtn, saveBtn } from "styles/MUIStyles";
import { useDebounce } from "usehooks-ts";

import SearchIcon from "@mui/icons-material/Search";
import { Box, Breadcrumbs, Button } from "@mui/material";

import ConfirmationModal from "../../../features/ConfirmationModal";
import { type RootState } from "../../../store";
import {
  updateVacationBalances,
} from "../../../store/thunks/vacationSickDaysRemainThunk";
import { sendNotification } from "../../../ui/Toast";

import RemainingDaysTable from "./VacationSickDaysRemainingTable";

import styles from "./VacationSickDaysRemainingDays.module.scss";

const VacationSickDaysRemainingDays = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { balances } = useAppSelector(
    (state: RootState) => state.vacationBalances,
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const debouncedSearch = useDebounce(searchQuery, 300);

  const [openCloseModal, setOpenCloseModal] = useState(false);

  const filteredData = useMemo(() => {
    if (!debouncedSearch) {
      return balances.map((balance) => ({
        personnelNumber: balance.personnelNumber,
        employeeId: balance.employeeId,
        name: balance.name ?? null,
        balance: balance.balance,
        year: balance.year,
      }));
    }
    return balances.filter((item) =>
      item.name?.toLowerCase().includes(debouncedSearch.toLowerCase()),
    );
  }, [debouncedSearch, balances]);

  const handleFormChange = () => {
    setHasUnsavedChanges(true);
  };

  const handleSave = (updatedData: any[]) => {
    if (updatedData.length > 0) {
      dispatch(updateVacationBalances(updatedData))
        .unwrap()
        .then(() => {
          sendNotification(t("notifications.successfullySaved"), "success");
          setHasUnsavedChanges(false);
        })
        .catch((error) => {
          const errorMessage = error;
          sendNotification(errorMessage, "error");
        });
    }
  };

  const handleCloseModal = () => {
    if (hasUnsavedChanges) {
      setOpenCloseModal(true);
    } else {
      navigate("/daysoff");
    }
  };

  const handleCloseConfirm = () => {
    navigate(-1);
    setOpenCloseModal(false);
  };

  return (
    <Box sx={{ width: "100%", padding: "10px 10px" }}>
      <Breadcrumbs sx={{ padding: "10px 10px" }}>
        <Link to="/daysoff">{t("menu.daysOff")}</Link>
      </Breadcrumbs>
      <div className={styles.boxTitle}>
        <h2>{t("vacationsSickDays.VacationsAndSickDays")} 2025</h2>
        <div>
          <Button
            type="submit"
            variant="contained"
            sx={saveBtn}
            onClick={() => {
              const tableInstance = document.querySelector("form");
              if (tableInstance) {
                tableInstance.dispatchEvent(
                  new Event("submit", { bubbles: true }),
                );
              }
            }}
          >
            {t("buttons.Save")}
          </Button>

          <Button variant="text" sx={cancelBtn} onClick={handleCloseModal}>
            {t("buttons.Close")}
          </Button>
        </div>
      </div>
      <div
        className={styles.mainText}>
        {t("vacationsSickDays.VacationBalanceText")}
      </div>
      <div className={styles.searchWrapper}>
        <input
          type="text"
          placeholder={t("filters.searchByNameAndSurname") as string}
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
        <button type="button" className={styles.searchWrapper_icon}>
          <SearchIcon sx={{ color: "#fff" }} />
        </button>
      </div>
      <div className={styles.tableWrapper}>
        {debouncedSearch && filteredData.length === 0 ? (
          <div style={{ textAlign: "center", marginTop: "20px", color: "red" }}>
            {t("notifications.noUserFound")}
          </div>
        ) : (
          <RemainingDaysTable
            data={filteredData}
            onSave={handleSave}
          />
        )}
      </div>
      <ConfirmationModal
        isModalOpen={openCloseModal}
        setIsModalOpen={setOpenCloseModal}
        actionName={"Close"}
        instanceName=""
        message={"closeFormWithoutSaving"}
        handleAction={handleCloseConfirm}
      />
    </Box>
  );
};

export default VacationSickDaysRemainingDays;
