import React, { type FC } from "react";
import { useTranslation } from "react-i18next";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";

import {
  activateOrDeactivateBtn,
  cancelBtn,
} from "../../styles/MUIStyles";

import styles from "./styles.module.scss";

const modalStyles = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
  zIndex: 1300,
  fontSize: "16px",

};

interface IVacationConflictModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  onExtendVacation: () => void;
  onReduceDays: () => void;
  conflictingDays: string[];
}

const VacationConflictModal: FC<IVacationConflictModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  onExtendVacation,
  onReduceDays,
  conflictingDays,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isModalOpen}>
        <Box sx={modalStyles}>
          <h2>{t("vacationConflict.title", "Конфликт в отпуске")}</h2>
          <p>
            {t(
              "vacationConflict.message",
            )}
          </p>
          <ul className={styles.listDays}>
            {conflictingDays.map((day) => (
              <li key={day}>{day}</li>
            ))}
          </ul>
          <p style={{ marginBottom: "10px" }}>
            {t(
              "vacationConflict.choiceMessage",
            )}
          </p>

          <div className={styles.buttonGroup}>
            <Button
              onClick={() => {
                if (onExtendVacation) onExtendVacation();
              }}
              variant="contained"
              color="primary"
              sx={{ marginRight: "10px" }}
            >
              {t("vacationConflict.extendVacation", { days: conflictingDays.length })}
            </Button>
            <Button
              onClick={() => {
                if (onReduceDays) onReduceDays();
              }}
              variant="contained"
              color="secondary"
              sx={{ marginRight: "10px" }}
            >
              {t("vacationConflict.reduceDays",{ days: conflictingDays.length })}
            </Button>
            <Button
              onClick={() => setIsModalOpen(false)}
              variant="text"
              sx={cancelBtn}
            >
              {t("vacationConflict.cancel")}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default VacationConflictModal;
