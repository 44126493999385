import axios from "axios";
import axiosApi from "core/request";
import { ISortConfig } from "models/user";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getDaysOffAll = createAsyncThunk(
  "getDaysOffAll",
  async (
    queryParams: {
      year: number;
      vacationsSickDaysPageNumber: number;
      vacationsSickDaysPageSize: number;
      debouncedFullNameSearch: string;
      sortConfigVacationsSickDays: ISortConfig;
      vacationsSickDaysFilters: any;
    },
    { rejectWithValue },
  ) => {
    try {
      const params = new URLSearchParams();

      params.append("filter.year", String(queryParams.year));
      params.append(
        "pageNumber",
        String(queryParams.vacationsSickDaysPageNumber),
      );
      params.append("pageSize", String(queryParams.vacationsSickDaysPageSize));

      if (queryParams.debouncedFullNameSearch) {
        params.append("filter.fullname", queryParams.debouncedFullNameSearch);
      }

      if (queryParams.sortConfigVacationsSickDays.column) {
        params.append(
          "sortBy",
          `${queryParams.sortConfigVacationsSickDays.column}.${queryParams.sortConfigVacationsSickDays.direction}`,
        );
      }

      for (const key in queryParams.vacationsSickDaysFilters) {
        if (queryParams.vacationsSickDaysFilters[key]) {
          params.append(
            `filter.${key}`,
            queryParams.vacationsSickDaysFilters[key],
          );
        }
      }

      const { data } = await axiosApi.get("daysoff/all", { params });
      return data;
    } catch (error) {
      let errorMessage = error;

      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.errors?.raw?.[0];
        // errorNotification(
        //     "Произошла ошибка, перезагрузите страницу..."
        // ); use Tostify Maksata
      } else {
        console.error(error);
      }

      return rejectWithValue(errorMessage);
    }
  },
);

export const getDaysOffByEmployee = createAsyncThunk(
  "getDaysOffByEmployee",
  async (
    args: {
      year: string;
      employeeId: string;
      role: string | null;
      month: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const query =
        args.role === "User"
          ? "daysoff/getByEmployeeId"
          : "daysoff/daysoffbyemployeeid";
      const params = new URLSearchParams();

      params.append("employeeId", args.employeeId);

      params.append("year", args.year);
      params.append("month", args.month);

      const { data } = await axiosApi.get(query, {
        params,
      });

      if (args.role === "User") {
        return {
          daysOff: data,
        };
      } else {
        return data;
      }
    } catch (error) {
      let errorMessage = error;

      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.errors?.raw?.[0];
        // errorNotification(
        //     "Произошла ошибка, перезагрузите страницу..."
        // ); use Tostify Maksata
      } else {
        console.error(error);
      }

      return rejectWithValue(errorMessage);
    }
  },
);

export const postDaysOffByEmployee = createAsyncThunk(
  "postDaysOffByEmployee",
  async (daysOffDto: any, { rejectWithValue }) => {
    try {
      await axiosApi.post("daysoff", { daysOffDto });
    } catch (error) {
      let errorMessage = error;

      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.errors?.raw?.[0];
        // errorNotification(
        //     "Произошла ошибка, перезагрузите страницу..."
        // ); use Tostify Maksata
      } else {
        console.error(error);
      }

      return rejectWithValue(errorMessage);
    }
  },
);

export const deleteDaysOffByEmployee = createAsyncThunk(
  "deleteDaysOffByEmployee",
  async (daysOffIds: number[], { rejectWithValue }) => {
    try {
      await axiosApi({
        method: "delete",
        url: "daysoff/delete",
        data: {
          ids: daysOffIds,
        },
      });
    } catch (error) {
      let errorMessage = error;

      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.errors?.raw?.[0];
        // errorNotification(
        //     "Произошла ошибка, перезагрузите страницу..."
        // ); use Tostify Maksata
      } else {
        console.error(error);
      }

      return rejectWithValue(errorMessage);
    }
  },
);
