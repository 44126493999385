// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import axiosApi from "core/request";
import dayjs, { Dayjs } from "dayjs";
import { useAppSelector } from "hooks";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { sendNotification } from "ui/Toast";

import { Button } from "@mui/material";

import VacationConflictModal from "../../../../features/VacationConflictModal";
import Loader from "../../../../ui/Loader";
import { Text } from "../../../../ui/Text/Text";

import { VacationCard } from "./components/VacationCard";

import styles from "./styles.module.scss";

interface CalendarData {
  allDay: boolean | null;
  description: string | null;
  endDate: string;
  id: number | null;
  startDate: string | null;
  title: string | null;
  type: number | null;
}

export const Vacations = () => {
  const { t } = useTranslation();
  const employeeId = useAppSelector((state) => state.auth.userId);
  const [isLoading, setIsLoading] = useState(false);
  const [holidaysToDelete, setHolidaysToDelete] = useState<number[]>([]);
  const [calendarData, setCalendarData] = useState<CalendarData[]>([]);

  const [addedHolidays, setAddedHolidays] = useState<
    {
      id: string | null;
      date: Dayjs | null;
      dateEnd: Dayjs | null;
      note: string;
    }[]
  >([]);

  const [metaInfo, setMetaInfo] = useState({
    maxHolidaysPerYear: 0,
    isHolidaysModifiable: false,
    isVacationModifiable: false,
    maxVacationDaysPerYear: 28,
    vacationBalance: 0,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [initialListOfDates, setInitialListOfDates] = useState([]);


  const calculateFilledDatesFromPureDays = () => {
    return [...calendarData, ...addedHolidays].reduce((acc, item) => {
      const pureDays = item.pureDays ||
        dayjs(item.dateEnd).diff(item.date, 'day') + 1;
      return acc + pureDays;
    }, 0);
  };

  const filledDates = calculateFilledDatesFromPureDays();


  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [calendarRes, metaRes] = await Promise.all([
        axiosApi.get<CalendarData[]>("/personal-cabinet/calendar", {
          params: { employeeId },
        }),
        axiosApi.get(`/personal-cabinet/calendar/meta?employeeId=${employeeId}`),
      ]);

      setInitialListOfDates(calendarRes.data);
      setCalendarData(calendarRes.data.filter((item: any) => item.type === 1));
      setMetaInfo(metaRes.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [employeeId]);


  const handleSave = async () => {
    try {
      const allDates = [...calendarData, ...addedHolidays];

      if (allDates.length !== 0) {
        const hasLongVacation = allDates?.some(d => (
          dayjs(typeof d.id === "string" ? d.dateEnd : d.endDate)
            ?.diff(dayjs(typeof d.id === "string" ? d.date : d.startDate), "day") + 1 >= 14
        ));

        if (!hasLongVacation) {
          throw new Error(t("vacationsSickDays.vacationDurationError"));
        }
      }

      setIsLoading(true);

      const daysOffDto = addedHolidays.map((holiday) => {
        const localStartDate = dayjs(holiday.date).startOf("day");
        const localEndDate = dayjs(holiday.dateEnd).startOf("day");
        const offset = localStartDate.utcOffset();
        const offsetInHours = dayjs().utcOffset() / 60;

        const adjustedDate = localStartDate
          .utc()
          .add(offset + offsetInHours, "minute")
          .format();

        const adjustedEndDate = localEndDate
          .utc()
          .add(offset + offsetInHours, "minute")
          .format();

        return {
          startDate: adjustedDate,
          finishDate: adjustedEndDate,
          sign: 1,
          remark: holiday.note || null,
          pureDays: holiday.pureDays
        };
      });

      if (holidaysToDelete.length > 0) {
        await axiosApi.delete("/personal-cabinet/calendar/daysoff", {
          data: { ids: holidaysToDelete },
        });
      }

      if (daysOffDto.length > 0) {
        const payload = {
          employeeId,
          daysOffDto,
        };

        await axiosApi.post("/personal-cabinet/calendar/daysoff", payload);
      }

      sendNotification(t("notifications.successfullySaved"), "success");

      setAddedHolidays([]);
      setHolidaysToDelete([]);
      setIsEdit(false);
      fetchData();
    } catch (error) {
      if (typeof error.message === "string") {
        sendNotification(error.message, "error");
        return;
      }
      sendNotification(t("statuses.Error"), "error");
      console.error("Error saving holidays:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleAddNewHoliday = (holiday: {
    date: Dayjs | null;
    dateEnd: Dayjs | null;
    note: string;
    id: string | null;
    pureDays?: number;
  }) => {
    setAddedHolidays((prev) => [...prev, holiday]);
  };

  const handleMarkForDelete = (id: number | string) => {
    // @ts-ignore
    setHolidaysToDelete((prev) => [...prev, id]);
    setCalendarData((prev) => prev.filter((item) => item.id !== id));
    setAddedHolidays((prev) => prev.filter((holiday) => holiday.id !== id));
  };

  const handleRemoveAddedHoliday = (id: string | null) => {
    setAddedHolidays((prev) => prev.filter((holiday) => holiday.id !== id));
  };



  return (
    <div>
      {!isLoading && (
        <div className={styles.meta_info}>
          <div>
            <div className={styles.box_year}>
              <p>2025</p>
            </div>
            <Text style={{ marginBottom: '10px' }} size={20} variant="REGULAR">
              {t("account.yearStartBalance")} &nbsp;
              <span className={styles.value_color}>
                {metaInfo.vacationBalance}
              </span>
            </Text>
            <Text style={{ marginBottom: '10px' }} size={20} variant="REGULAR">
              {t("account.paidVacation")} &nbsp;
              <span className={styles.value_color}>
                28
              </span>
            </Text>
            <Text size={20} variant="REGULAR" style={{ marginTop: "20px" }}>
              {t("account.filled-in")}
              <span className={styles.value_color}>
                &nbsp; {filledDates <= metaInfo.maxVacationDaysPerYear ? filledDates : metaInfo.maxVacationDaysPerYear} &nbsp;
              </span>
              {t("account.of")}
              <span className={styles.value_color}>
                &nbsp; {metaInfo.maxVacationDaysPerYear}
              </span>
            </Text>
          </div>
          <div style={{ marginLeft: '50px' }} className={styles.meta_info}>
            {metaInfo.isVacationModifiable && (
              <Button
                disabled={isLoading}
                variant="contained"
                onClick={isEdit ? handleSave : () => setIsEdit(!isEdit)}
              >
                {isEdit ? t("buttons.Save") : t("buttons.Edit")}
              </Button>
            )}
            {isEdit && (
              <Button
                disabled={isLoading}
                variant="contained"
                onClick={() => {
                  setIsEdit(!isEdit);
                  setAddedHolidays([]);
                  setHolidaysToDelete([]);
                  fetchData();
                }}
              >
                {t("buttons.Close")}
              </Button>
            )}
          </div>
        </div>
      )}
      <div className={styles.cards_container}>
        {isLoading ? (
          <div
            style={{
              width: "100%",
            }}
          >
            <Loader />
          </div>
        ) : isEdit ? (
          <>
            {metaInfo.isVacationModifiable && (
              <VacationCard
                maxVacationDaysPerYear={metaInfo.maxVacationDaysPerYear}
                filledDates={filledDates}
                addedHolidays={addedHolidays}
                isNew
                onAdd={handleAddNewHoliday}
                allDates={[...calendarData, ...addedHolidays]}
                initialListOfDates={initialListOfDates}
              />
            )}

            {[...calendarData, ...addedHolidays].map((item: any) => (
              <VacationCard
                dateEnd={
                  typeof item.id === "number" ? item.endDate : item.dateEnd
                }
                key={item.id || item.id}
                id={item.id || null}
                date={typeof item.id === "string" ? item.date : item.startDate}
                note={typeof item.id === "string" ? item.note : item.description}
                onDelete={
                  typeof item.id === "number"
                    ? () => handleMarkForDelete(item.id!)
                    : () => handleRemoveAddedHoliday(item.id)
                }
              />
            ))}
          </>
        ) : (
          <div>
            <div className={styles.event_wrapper}>
              {calendarData.map((item: CalendarData, idx) => {
                const startDate = moment(item.startDate);
                const endDate = moment(item.endDate);
                const daysDifference = endDate.diff(startDate, "days") + 1;

                return (
                  <div key={item.id} className={styles.event}>
                    <span>
                      <b>{idx + 1}</b>
                    </span>
                    <span>{startDate.format("DD.MM.YYYY")}</span>
                    <span>-</span>
                    <span>{endDate.format("DD.MM.YYYY")}</span>
                    <b>{daysDifference}</b>
                    <span>{item.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
