import { createSlice } from "@reduxjs/toolkit";

import { fetchVacationBalances, updateVacationBalances } from "../thunks/vacationSickDaysRemainThunk";


interface VacationBalance {
  personnelNumber: number;
  employeeId: string;
  name: string;
  balance: string;
  year: number;
}

interface VacationBalancesState {
  balances: VacationBalance[];
  loading: boolean;
  error: string | null;
  updatedBalances: VacationBalance[]; // Для отслеживания изменений
}

const initialState: VacationBalancesState = {
  balances: [],
  loading: false,
  error: null,
  updatedBalances: [],
};


const vacationBalancesSlice = createSlice({
  name: "vacationBalances",
  initialState,
  reducers: {
    updateBalance(state, action) {
      const { personnelNumber, balance } = action.payload;
      const index = state.balances.findIndex((b) => b.personnelNumber === personnelNumber);
      if (index !== -1) {
        state.balances[index].balance = balance;
        const updatedIndex = state.updatedBalances.findIndex((b) => b.personnelNumber === personnelNumber);
        if (updatedIndex !== -1) {
          state.updatedBalances[updatedIndex].balance = balance;
        } else {
          state.updatedBalances.push(state.balances[index]);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVacationBalances.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVacationBalances.fulfilled, (state, action) => {
        state.balances = action.payload;
        state.loading = false;
      })
      .addCase(fetchVacationBalances.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateVacationBalances.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateVacationBalances.fulfilled, (state) => {
        state.updatedBalances = [];
        state.loading = false;
      })
      .addCase(updateVacationBalances.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { updateBalance } = vacationBalancesSlice.actions;

export default vacationBalancesSlice.reducer;
